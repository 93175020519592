import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap
} from 'react-grid-dnd';
import { Modal, ModalBody } from 'reactstrap';

import {
  Button,
  CloseIcon,
  ProductMediaToggle,
  Text
} from '@/components/atomic/nuclei';
import { LINE_ITEM_SOURCE, staticMediaStoreBaseURL } from '@/config/common';
import {
  GRID_BOXES_PER_ROW,
  GRID_BOX_HEIGHT,
  downloadImage,
  getDropZoneHeight,
  getImageURL
} from '@/helpers/carousel';
import {
  onDeleteOrderItemMedia,
  updateOrderItemMediaSortOrder
} from '@/services/order.service';
import { noProductImageURL } from '@/services/plannerPortal.service';

const saveSortOrder = ({
  itemId,
  itemMedia,
  itemParentId,
  productSource,
  router,
  setPendingReorderRequest,
  setShowToast,
  updateCartItemMediaSortOrder
}) => {
  const changedItemMediaSortOrder = itemMedia.map(
    ({ cartItemMediaId, orderItemMediaId }, index) => ({
      id: cartItemMediaId || orderItemMediaId,
      sortOrder: index + 1
    })
  );
  if (productSource === LINE_ITEM_SOURCE.ORDER.value) {
    updateOrderItemMediaSortOrder({
      changedOrderItemMediaSortOrder: changedItemMediaSortOrder,
      orderId: itemParentId,
      orderItemId: itemId,
      router,
      setShowToast
    });
  } else {
    updateCartItemMediaSortOrder({
      changedCartItemMediaSortOrder: changedItemMediaSortOrder,
      cartItemId: itemId
    });
  }

  setPendingReorderRequest(false);
};

const SaveCancelCTA = ({
  additionalLineItemMedia,
  itemId,
  itemMedia,
  itemParentId,
  productSource,
  setItemMedia,
  setPendingReorderRequest,
  setShowToast,
  updateCartItemMediaSortOrder
}) => {
  const router = useRouter();
  return (
    <div className='flex gap-2'>
      <Button
        {...{
          className:
            'bg-gray px-3 py-2 justify-center md:text-base text-gray-700 rounded-lg text-sm font-medium',
          label: 'Cancel',
          onClick: () => {
            setPendingReorderRequest(false);
            setItemMedia(additionalLineItemMedia);
          }
        }}
      />
      <Button
        {...{
          className:
            'bg-gradient-to-r from-light-orange via-dark-orange to-rose text-white px-3 py-2 rounded-lg text-sm font-medium',
          label: 'Save',
          onClick: () =>
            saveSortOrder({
              itemId,
              itemMedia,
              itemParentId,
              productSource,
              router,
              setPendingReorderRequest,
              setShowToast,
              updateCartItemMediaSortOrder
            })
        }}
      />
    </div>
  );
};

const ItemMediaHeader = ({
  additionalLineItemMedia,
  isCartEditable,
  isReadOnly,
  itemId,
  itemMedia,
  itemParentId,
  pendingReorderRequest,
  productSource,
  setCartItemIdForUploadMediaModal,
  setItemMedia,
  setOpenImageGalleryModal,
  setOrderItemIdForUploadMediaModal,
  setPendingReorderRequest,
  setShowToast,
  unAvailableCartItem,
  updateCartItemMediaSortOrder
}) => {
  const showUploadMediaCTA =
    isCartEditable &&
    !unAvailableCartItem &&
    !pendingReorderRequest &&
    !isReadOnly;
  return (
    <div className='flex justify-between mb-4'>
      <Text
        className='text-base font-medium text-dim-gray'
        content='Additional Media'
      />
      {showUploadMediaCTA && (
        <UploadMedia
          {...{
            itemId,
            productSource,
            setCartItemIdForUploadMediaModal,
            setOpenImageGalleryModal,
            setOrderItemIdForUploadMediaModal
          }}
        />
      )}
      {pendingReorderRequest && (
        <SaveCancelCTA
          {...{
            additionalLineItemMedia,
            itemId,
            itemMedia,
            itemParentId,
            productSource,
            setItemMedia,
            setPendingReorderRequest,
            setShowToast,
            updateCartItemMediaSortOrder
          }}
        />
      )}
    </div>
  );
};

const MediaGridItem = ({
  itemId,
  itemParentId,
  media,
  onDeleteCartItemMedia,
  productSource,
  setCarouselModalImage,
  setShowToast,
  showDeleteIcon = false
}) => {
  const router = useRouter();
  const [imageURL, setImageURL] = useState(getImageURL(media));
  return (
    <div className='relative planner-cart-gallery-box rounded-xl cursor-pointer border border-platinum'>
      <Image
        alt='planner-cart-gallery'
        className='rounded-xl'
        fill
        onError={() => setImageURL(noProductImageURL)}
        sizes='(max-width: 600px) 100vw, 50vw'
        src={imageURL}
        style={{ objectFit: 'contain' }}
      />
      <div className='absolute black-overlayer w-full h-full rounded-xl z-10 flex items-center justify-center gap-3'>
        <Image
          alt='download image'
          className='cursor-pointer'
          height={0}
          onClick={() =>
            downloadImage({
              imageUrl: getImageURL(media),
              filename: media.filename || media.url.replaceAll('/', '_')
            })
          }
          src={`${staticMediaStoreBaseURL}/icons/download-white-icon-v1.svg`}
          sizes='(max-width: 600px) 100vw, 50vw'
          style={{ height: 20, width: 20 }}
          width={0}
        />
        <Image
          alt='view'
          className='cursor-pointer'
          height={0}
          onClick={() => setCarouselModalImage(media)}
          src={`${staticMediaStoreBaseURL}/icons/view-icon.svg`}
          style={{ height: 20, width: 20 }}
          width={0}
        />
        {showDeleteIcon && (
          <Image
            alt='delete'
            className='cursor-pointer'
            height={0}
            onClick={() => {
              if (productSource === LINE_ITEM_SOURCE.ORDER.value) {
                onDeleteOrderItemMedia({
                  orderId: itemParentId,
                  orderItemId: itemId,
                  orderItemMediaId: media.orderItemMediaId,
                  router,
                  setShowToast
                });
              } else {
                onDeleteCartItemMedia({
                  cartItemId: itemId,
                  cartItemMediaId: media.cartItemMediaId
                });
              }
            }}
            src={`${staticMediaStoreBaseURL}/icons/delete-white-icon.svg`}
            style={{ height: 20, width: 20 }}
            width={0}
          />
        )}
      </div>
    </div>
  );
};

// TODO: Viresh, Hardik - find an alternate to style, so that class can be used in GridDropZone
const CartItemMediaGrid = ({
  itemId,
  itemParentId,
  mediaList = [],
  onDeleteCartItemMedia,
  onDragImageTile,
  productSource,
  setCarouselModalImage,
  setShowToast,
  showDeleteIcon = false
}) => (
  <GridContextProvider onChange={onDragImageTile}>
    <GridDropZone
      boxesPerRow={GRID_BOXES_PER_ROW}
      id='MediaGrid-images'
      rowHeight={GRID_BOX_HEIGHT}
      style={{ height: getDropZoneHeight({ mediaList }) }}
    >
      {mediaList.map((media) => (
        <GridItem key={media.id}>
          <MediaGridItem
            {...{
              itemId,
              media,
              onDeleteCartItemMedia,
              itemParentId,
              productSource,
              setCarouselModalImage,
              setShowToast,
              showDeleteIcon
            }}
          />
        </GridItem>
      ))}
    </GridDropZone>
  </GridContextProvider>
);

const MediaGrid = ({ mediaList = [], setCarouselModalImage }) =>
  mediaList.map((media, index) => (
    <MediaGridItem
      key={index}
      {...{
        media,
        setCarouselModalImage
      }}
    />
  ));

const UploadMedia = ({
  itemId,
  productSource,
  setCartItemIdForUploadMediaModal,
  setOpenImageGalleryModal,
  setOrderItemIdForUploadMediaModal
}) => (
  <div
    className='items-center text-sm font-medium text-brand flex border border-brand rounded-md px-4 py-2 cursor-pointer'
    onClick={() => {
      if (productSource === LINE_ITEM_SOURCE.ORDER.value) {
        setOrderItemIdForUploadMediaModal(itemId);
      } else {
        setCartItemIdForUploadMediaModal(itemId);
      }
      setOpenImageGalleryModal(false);
    }}
  >
    <Image
      alt='upload image'
      className='px-1'
      height={0}
      src={`${staticMediaStoreBaseURL}/icons/export-brand-icon.svg`}
      style={{ height: 24, width: 24 }}
      width={0}
    />
    Upload Media
  </div>
);

const ImageGalleryModal = ({
  additionalLineItemMedia,
  isCartEditable,
  isReadOnly,
  isShowProductMedia,
  itemId,
  itemMediaForCarousel,
  itemParentId,
  onClickToggleButton,
  onDeleteCartItemMedia,
  openImageGalleryModal,
  productName,
  productSource,
  setCarouselModalImage,
  setCartItemIdForUploadMediaModal,
  setOpenImageGalleryModal,
  setOrderItemIdForUploadMediaModal,
  setShowToast,
  unAvailableCartItem,
  updateCartItemMediaSortOrder
}) => {
  const productMedia = itemMediaForCarousel.filter(
    ({ entityType }) => entityType === 'product'
  );

  const [pendingReorderRequest, setPendingReorderRequest] = useState(false);
  const [itemMedia, setItemMedia] = useState(additionalLineItemMedia);

  const keyPressEventAction = ({ key: keyPressed }) =>
    keyPressed === 'Escape' && setOpenImageGalleryModal(false);

  useEffect(() => {
    document.addEventListener('keyup', keyPressEventAction);
    return () => document.removeEventListener('keyup', keyPressEventAction);
  }, []);

  const onDragImageTile = (sourceId, sourceIndex, targetIndex) => {
    setPendingReorderRequest(true);
    const sortedItemMediaList = swap(itemMedia, sourceIndex, targetIndex);
    setItemMedia(sortedItemMediaList);
  };

  const additionalLineItemMediaPresent = additionalLineItemMedia?.length > 0;

  const showProductMediaToggle =
    additionalLineItemMediaPresent || !isShowProductMedia;

  return (
    <Modal
      centered
      size='lg'
      isOpen={openImageGalleryModal}
      backdrop={true}
      fade={false}
    >
      <ModalBody>
        <div className='m-6 bg-white rounded-xl px-6 pb-6'>
          <div className='flex justify-between pt-7 mb-4'>
            <div className='text-xl font-medium text-nero'>
              <Text
                className='text-xl font-medium text-dim-gray pb-3'
                content={productName}
              />
              {showProductMediaToggle && (
                <ProductMediaToggle
                  {...{
                    disable: isReadOnly,
                    isShowProductMedia,
                    onClickToggleButton
                  }}
                />
              )}
            </div>
            <CloseIcon
              {...{ onClick: () => setOpenImageGalleryModal(false) }}
            />
          </div>
          <div className='h-80vh'>
            <ItemMediaHeader
              {...{
                additionalLineItemMedia,
                isCartEditable,
                isReadOnly,
                itemId,
                itemMedia,
                itemParentId,
                pendingReorderRequest,
                productSource,
                setCartItemIdForUploadMediaModal,
                setItemMedia,
                setOpenImageGalleryModal,
                setOrderItemIdForUploadMediaModal,
                setPendingReorderRequest,
                setShowToast,
                unAvailableCartItem,
                updateCartItemMediaSortOrder
              }}
            />
            {!unAvailableCartItem ? (
              <CartItemMediaGrid
                {...{
                  itemId,
                  itemParentId,
                  mediaList: itemMedia,
                  onDeleteCartItemMedia,
                  onDragImageTile,
                  productSource,
                  setCarouselModalImage,
                  setShowToast,
                  showDeleteIcon: !isReadOnly
                }}
              />
            ) : (
              <div className='flex flex-wrap gap-4'>
                <MediaGrid
                  {...{
                    mediaList: itemMedia,
                    setCarouselModalImage
                  }}
                />
              </div>
            )}
            <Text
              className='text-base font-medium text-dim-gray mt-6 mb-4'
              content='Existing Media'
            />
            <div className='flex flex-wrap gap-4'>
              {productMedia && (
                <MediaGrid
                  {...{
                    mediaList: productMedia,
                    setCarouselModalImage
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ImageGalleryModal;
