import { useEffect } from 'react';

const RadioButtons = ({
  handleOptionChange,
  onChangeOptionUiState,
  radioInputProps,
  selectedOption
}) => {
  useEffect(() => {
    onChangeOptionUiState();
  }, [selectedOption]);

  return (
    <div className='flex flex-col gap-2'>
      {radioInputProps.map(
        ({ checked, label, show = true, value }, index) =>
          show && (
            <li
              key={index}
              className='relative block searchkit-filter-menu-item cursor-pointer'
            >
              <label className='cursor-pointer flex items-center self-center'>
                <input
                  {...{
                    checked,
                    className:
                      'checked:content-searchkit-filter-menu-item-checked searchkit-filter-menu-item-checked searchkit-radio',
                    name: 'options',
                    onChange: handleOptionChange,
                    type: 'radio',
                    value
                  }}
                />
                <span className='before:bg-searchkit-filter-menu-item empty-radio-span inline-block w-5 h-5'></span>
                <div className='text-sm text-dim-gray font-medium pl-2'>
                  {label}
                </div>
              </label>
            </li>
          )
      )}
    </div>
  );
};

export default RadioButtons;
