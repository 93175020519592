import { ZENDESK_CONFIG } from '@/config/common';

const getZendeskTicketUrl = (zendeskTicketId) =>
  `${ZENDESK_CONFIG.agentTicketViewURL}${zendeskTicketId}`;

export const handleOnClickZendesk = (zendeskTicketId) => {
  zendeskTicketId &&
    window.open(getZendeskTicketUrl(zendeskTicketId), '_blank');
};

export default handleOnClickZendesk; // TODO: Remove this when add another export.
