import Image from 'next/image';

import {
  Button,
  CloseIcon,
  Text,
  TextKeyValuePair,
  TextWithIcon
} from '@/components/atomic/nuclei';
import {
  mediaStoreBaseURL,
  staticMediaStoreBaseURL,
  USER_EVENT_ACTION,
  USER_EVENT_SOURCE
} from '@/config/common';
import { handleOnClickZendesk } from '@/helpers/zendeskTicket';
import { getSerializedRichText } from '@/lib/editor';
import { convertToDateTimeString } from '@/lib/time';
import { isEmptyOrNull, parseNumberedLabel } from '@/lib/utils';
import { updateAllocationStatus } from '@/services/actionCenter.service';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';
import {
  getLabelsBasedOnAllocationStatusAndEPICProgress,
  shouldShowAcceptReassignCTA
} from '@/services/userEvent.service';
import { getLabelForEventRequestIsEndToEnd } from '@/services/userEventCartList.service';

const ActionPanel = ({
  isAcceptSelected,
  isReassignSelected,
  labels,
  setValue,
  showAcceptReassignCTA
}) => (
  <div className='px-6'>
    <Text {...{ className: 'text-nero font-medium', content: 'Action' }} />
    <div className='flex gap-4 pb-20 md:pb-0 mt-4'>
      {showAcceptReassignCTA ? (
        <>
          <Button
            {...{
              alt: isAcceptSelected ? 'radio checked brand' : 'radio',
              className: `border rounded-lg py-3 text-dim-gray font-medium ${
                isAcceptSelected ? 'border-brand' : 'border-[#E5E5EB]'
              }`,
              iconPosition: 'Left',
              iconURL: `${staticMediaStoreBaseURL}/icons/${
                isAcceptSelected ? 'radio-checked-brand' : 'radio'
              }.svg`,
              label: USER_EVENT_ACTION.ACCEPT.label,
              onClick: () => setValue('action', USER_EVENT_ACTION.ACCEPT.value)
            }}
          />
          <Button
            {...{
              alt: isReassignSelected ? 'radio checked brand' : 'radio',
              className: `border rounded-lg py-3 text-dim-gray font-medium ${
                isReassignSelected ? 'border-brand' : 'border-[#E5E5EB]'
              }`,
              iconPosition: 'Left',
              iconURL: `${staticMediaStoreBaseURL}/icons/${
                isReassignSelected ? 'radio-checked-brand' : 'radio'
              }.svg`,
              label: USER_EVENT_ACTION.REASSIGN.label,
              onClick: () =>
                setValue('action', USER_EVENT_ACTION.REASSIGN.value)
            }}
          />
        </>
      ) : (
        labels.map((labelProps, index) => (
          <Text
            key={`${labelProps.content}_${index}`}
            {...labelProps}
          />
        ))
      )}
    </div>
  </div>
);

const NewAssignedUserEventPreviewCard = ({
  handleClose,
  router,
  selectedUserEventAction,
  setLoading,
  setShowToast,
  setValue,
  upcomingUserEventDetail,
  watch
}) => {
  const {
    allocationStatus,
    checkoutEventIconUrl,
    epicGenerationProgressPercentage,
    eventCityOrAddress,
    eventDate,
    eventRequestIsEndToEnd,
    eventStartTime,
    eventType,
    eventVerticalNames,
    expectedGuestCount,
    hostCreditEntityId,
    hostCreditEntityName,
    hostId,
    hostName,
    hostSegmentName,
    stage,
    triageSummary,
    userEventId,
    userEventNumber,
    zendeskTicketId
  } = upcomingUserEventDetail;

  const showAcceptReassignCTA = shouldShowAcceptReassignCTA({
    allocationStatus,
    stage
  });

  const labels = getLabelsBasedOnAllocationStatusAndEPICProgress({
    allocationStatus,
    epicGenerationProgressPercentage,
    stage
  });

  const previewCardHeaderDetails = [
    {
      alt: 'view eye red icon',
      className:
        'shadow-none border border-brand rounded-lg px-2 py-1 cursor-pointer',
      icon: 'view-eye-red.svg',
      label: 'Event',
      labelStyle: 'text-sm font-medium text-brand',
      onClick: () =>
        window.open(
          getPageURL({
            pageName: PAGE_NAME.EVENT_CART_LIST.label,
            pathParams: { userEventId }
          }),
          '_blank'
        )
    },
    {
      alt: 'user event brand icon',
      className: 'shadow-none border border-neutral rounded-lg px-2 py-1',
      icon: 'user-event-brand-icon.svg',
      iconHeight: 16,
      iconWidth: 16,
      id: `user-event-number-${userEventId}`,
      label: parseNumberedLabel(userEventNumber),
      labelStyle:
        'text-sm font-medium text-dim-gray cursor-pointer hover:!text-brand',
      onClick: () => {
        router.push(
          getPageURL({
            pageName: PAGE_NAME.HOST_EVENT_PROFILE.label,
            pathParams: {
              userEventId
            }
          })
        );
      },
      onHoverCopy: true,
      tooltipText: 'User Event Number'
    }
  ];

  const previewCardBodyDetails = [
    {
      alt: 'zendesk with frame icon',
      icon: 'zendesk-with-frame.svg',
      id: `zendesk-ticket-id-${userEventId}`,
      label: parseNumberedLabel(zendeskTicketId),
      labelStyle:
        'text-sm font-medium text-dim-gray cursor-pointer hover:!text-brand',
      onClick: () => handleOnClickZendesk(zendeskTicketId),
      onHoverCopy: true,
      tooltipText: 'Zendesk Ticket Id'
    },
    {
      alt: 'host name icon',
      icon: 'host-brand-v1-icon.svg',
      id: `host-name-${userEventId}`,
      label: hostName || 'NA',
      labelStyle:
        'text-sm font-medium text-dim-gray cursor-pointer hover:!text-brand',
      onClick: () =>
        router.push(
          getPageURL({
            pageName: PAGE_NAME.HOST_EVENT_LIST.label,
            pathParams: {
              userId: hostId
            }
          })
        ),
      onHoverCopy: true,
      tooltipText: 'Host Name'
    },
    {
      alt: 'host credit entity icon',
      icon: 'host-credit-entity-brand-icon.svg',
      id: `host-credit-entity-name-${userEventId}`,
      label: hostCreditEntityName || 'NA',
      labelStyle: `text-sm font-medium text-dim-gray cursor-pointer ${hostCreditEntityId ? 'hover:!text-brand' : ''}`,
      onClick: () => {
        hostCreditEntityId &&
          router.push(
            getPageURL({
              pageName: PAGE_NAME.HOST_CREDIT_ENTITY_HOST_LIST.label,
              pathParams: {
                hostCreditEntityId
              }
            })
          );
      },
      onHoverCopy: true,
      tooltipText: 'Host Credit Entity Name'
    },
    {
      alt: 'host type icon',
      icon: 'host-type-brand-v1-icon.svg',
      id: `host-type-${userEventId}`,
      label: hostSegmentName || 'NA',
      onHoverCopy: true,
      tooltipText: 'Host Type'
    },
    {
      alt: 'date brand icon',
      icon: 'date-brand-icon.svg',
      id: `event-date-time-${userEventId}`,
      label: eventDate
        ? convertToDateTimeString({
            date: eventDate,
            time: eventStartTime
          })
        : 'NA',
      onHoverCopy: true,
      tooltipText: 'Event Date & Time'
    },
    {
      alt: 'event location icon',
      icon: 'location-brand-icon.svg',
      id: `location-${userEventId}`,
      label: eventCityOrAddress || 'NA',
      onHoverCopy: true,
      tooltipText: 'Event Location'
    },
    {
      alt: 'core vertical brand icon',
      icon: 'core-vertical-brand-icon.svg',
      id: `hafla-request-verticals-${userEventId}`,
      label: eventVerticalNames || 'NA',
      onHoverCopy: true,
      tooltipText: 'Hafla Request Verticals'
    },
    {
      alt: 'guest count brand icon',
      icon: 'guest-brand-icon.svg',
      id: `expected-guest-count-${userEventId}`,
      label: expectedGuestCount || 'NA',
      onHoverCopy: true,
      tooltipText: 'Expected Guest Count'
    },
    {
      alt: 'summary icon',
      icon: 'solution-design-brand-icon.svg',
      id: `summary-${userEventId}`,
      label: getSerializedRichText(triageSummary) || 'NA',
      labelStyle: 'text-sm font-medium text-dim-gray',
      onHoverCopy: true,
      tooltipText: 'Summary'
    }
  ];

  const isReassignSelected =
    selectedUserEventAction === USER_EVENT_ACTION.REASSIGN.value;

  const isAcceptSelected =
    selectedUserEventAction === USER_EVENT_ACTION.ACCEPT.value;

  return (
    <div
      className={`fixed bg-white w-full md:w-[49.5%] right-0 top-0 h-screen rounded-lg z-101 shadow-navigate-cart`}
    >
      <div className='flex flex-col h-[89vh]'>
        <div className='flex justify-between items-center shadow-navigate-cart md:shadow-none px-6 py-4'>
          <Text
            {...{
              className: 'text-base text-dim-gray font-medium',
              content: `Viewing Event ${parseNumberedLabel(userEventNumber)}`
            }}
          />
          <CloseIcon
            {...{
              className: 'absolute right-4 top-4',
              height: 32,
              icon: 'close-brand-icon.svg',
              onClick: handleClose,
              width: 32
            }}
          />
        </div>
        <div className='flex-1 overflow-y-auto px-6'>
          <div className='flex flex-col gap-4 px-6'>
            <div className='flex items-center gap-4 mt-5'>
              <Image
                alt='event image'
                className='rounded-lg'
                height={0}
                src={`${mediaStoreBaseURL}/${checkoutEventIconUrl}`}
                style={{ height: 100, width: 100 }}
                unoptimized={true}
                width={0}
              />
              <TextKeyValuePair
                {...{
                  className: 'flex-col !gap-1',
                  label: eventType,
                  labelClass: 'text-lg text-brand font-semibold',
                  spaceTop: '',
                  value: getLabelForEventRequestIsEndToEnd(
                    eventRequestIsEndToEnd
                  ),
                  valueClassName: 'text-nero text-base font-medium'
                }}
              />
            </div>
            <div className='flex justify-between'>
              {previewCardHeaderDetails.map(
                (previewCardHeaderDetail, index) => (
                  <TextWithIcon
                    key={`${previewCardHeaderDetail.label}_${index}`}
                    {...{
                      ...previewCardHeaderDetail,
                      iconHeight: 16,
                      iconWidth: 16
                    }}
                  />
                )
              )}
            </div>
            <div className='flex flex-col gap-4 mt-1 pb-3 border-b border-neutral'>
              {previewCardBodyDetails.map((previewCardBodyDetail, index) => {
                const { labelStyle = 'text-sm font-medium text-dim-gray' } =
                  previewCardBodyDetail;
                return (
                  <TextWithIcon
                    key={`${previewCardBodyDetail.label}_${index}`}
                    {...{
                      ...previewCardBodyDetail,
                      className: 'shadow-none',
                      iconHeight: 20,
                      iconWidth: 20,
                      labelStyle
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <ActionPanel
          {...{
            isAcceptSelected,
            isReassignSelected,
            labels,
            setValue,
            showAcceptReassignCTA
          }}
        />
        <div
          className={`fixed flex gap-4 fixed bg-white z-10 p-3 md:px-6 md:py-3 bottom-0 border-t border-brand justify-end !place-content-center md:!place-content-end swipeable-footer-view md:w-1/2`}
        >
          <Button
            {...{
              className:
                'bg-neutral text-dim-gray text-sm font-medium py-3 rounded-lg',
              label: 'Close',
              onClick: handleClose,
              width: 'w-40'
            }}
          />
          <Button
            {...{
              className:
                'bg-brand-gradient text-white text-sm font-medium py-3 rounded-lg',
              disabled:
                isEmptyOrNull(selectedUserEventAction) ||
                !isEmptyOrNull(labels),
              label: 'Confirm',
              onClick: () =>
                updateAllocationStatus({
                  payload: {
                    allocationStatus: watch('action'),
                    source: USER_EVENT_SOURCE.PLANNER_PORTAL_UPDATE_USER_EVENT,
                    zendeskTicketId
                  },
                  router,
                  setLoading,
                  setShowToast,
                  userEventId
                }),
              width: 'w-40'
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default NewAssignedUserEventPreviewCard;
