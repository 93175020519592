import Image from 'next/image';

import { Text, TextKeyValuePair } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import { CREDIT_REQUEST_STATUS } from '@/config/hostCreditEntity';
import {
  convertToCurrencyFormat,
  parseFormatPriceNumberValueFromAPI
} from '@/lib/numberStringUtils';
import { convertToShortMonthDateFormat } from '@/lib/time';

const CardHead = ({
  createdAt,
  focusedCreditRequestCardId,
  id,
  requestedCreditLimit,
  requester: { name: requesterName },
  setFocusedCreditRequestCardId,
  status
}) => (
  <div
    className='flex gap-2 items-center py-3 px-4'
    onClick={() =>
      setFocusedCreditRequestCardId((prevId) => (prevId === id ? null : id))
    }
  >
    <div className='flex gap-1'>
      <TextKeyValuePair
        {...{
          className: 'flex-row',
          label: requesterName,
          labelClass: 'font-medium',
          value: 'requested a credit facility of',
          valueClassName: 'font-light px-1'
        }}
      />
      <Text
        {...{
          className: 'font-medium',
          content: convertToCurrencyFormat({
            value: parseFormatPriceNumberValueFromAPI(requestedCreditLimit)
          })
        }}
      />
      <TextKeyValuePair
        {...{
          className: 'flex-row',
          label: 'on',
          labelClass: 'font-light',
          value: convertToShortMonthDateFormat(createdAt),
          valueClassName: 'font-medium'
        }}
      />
    </div>
    <Text
      {...{
        className: `${CREDIT_REQUEST_STATUS[status].className} text-xs font-medium rounded-lg px-2 py-1 ml-auto`,
        content: CREDIT_REQUEST_STATUS[status].label
      }}
    />
    <Image
      alt='open credit request'
      className={`cursor-pointer transform ${focusedCreditRequestCardId === id ? 'rotate-90' : 'rotate-0'}`}
      height={0}
      src={`${staticMediaStoreBaseURL}/icons/right-angle-red.svg`}
      style={{ height: 16, width: 16 }}
      width={0}
    />
  </div>
);

const CardBody = ({
  approvedCreditLimit,
  requestedCreditLimit,
  requester: { name: requesterName },
  reviewer,
  reviewerNotes
}) => {
  const { name: reviewerName } = reviewer || {};
  return (
    <div className='p-4 bg-brand-gradient'>
      <div className='w-full flex justify-between'>
        <div className='flex items-center gap-3'>
          <Image
            alt='credit approved icon'
            height={0}
            src={`${staticMediaStoreBaseURL}/icons/credit-approved-white-icon-v1.svg`}
            style={{ height: 44, width: 44 }}
            width={0}
          />
          <TextKeyValuePair
            {...{
              label: `Approved: ${approvedCreditLimit ? convertToCurrencyFormat({ value: parseFormatPriceNumberValueFromAPI(approvedCreditLimit) }) : 'NA'}`,
              labelClass: 'text-white text-lg font-semibold',
              spaceTop: 'mt-0 !gap-0',
              value: `Requested: ${convertToCurrencyFormat({ value: parseFormatPriceNumberValueFromAPI(requestedCreditLimit) })}`,
              valueClassName: 'text-white text-xs font-medium'
            }}
          />
        </div>
        <TextKeyValuePair
          {...{
            label: `Reviewed By: ${reviewerName || 'NA'}`,
            labelClass: 'text-white text-lg font-semibold',
            spaceTop: 'mt-0 !gap-0',
            value: `Requested By: ${requesterName}`,
            valueClassName: 'text-white text-xs font-medium'
          }}
        />
      </div>
      {reviewerNotes && (
        <TextKeyValuePair
          {...{
            className: 'flex-row gap-2',
            label: 'Reviewer Notes:',
            labelClass: 'text-white text-sm font-medium w-[7.3rem]',
            spaceTop: 'mt-3 !gap-1',
            value: reviewerNotes,
            valueClassName: 'text-white text-sm font-light flex flex-1'
          }}
        />
      )}
    </div>
  );
};

const CardFooter = ({
  creditRequestReason: { name: creditRequestReasonName },
  decisionDate,
  repaymentTermDays
}) => (
  <div className='flex flex-col gap-2 w-full p-4'>
    <div className='flex justify-between items-center'>
      <TextKeyValuePair
        {...{
          className: 'flex-row',
          label: 'Payment Terms (in days):',
          labelClass: 'text-dim-gray text-sm font-medium',
          value: repaymentTermDays,
          valueClassName: 'text-dim-gray text-sm'
        }}
      />
      <TextKeyValuePair
        {...{
          className: 'flex-row',
          label: 'Reviewed On:',
          labelClass: 'text-dim-gray text-sm font-medium',
          value: decisionDate
            ? convertToShortMonthDateFormat(decisionDate)
            : 'NA',
          valueClassName: 'text-dim-gray text-sm'
        }}
      />
    </div>
    <TextKeyValuePair
      {...{
        className: 'flex-row !gap-0',
        label: 'Reason For Credit:',
        labelClass: 'text-dim-gray text-sm font-medium w-[8.4rem]',
        value: creditRequestReasonName,
        valueClassName: 'text-dim-gray text-sm flex flex-1'
      }}
    />
  </div>
);

const CreditEntityCreditHistoryCard = ({
  focusedCreditRequestCardId,
  historicalCreditRequest: {
    approvedCreditLimit,
    createdAt,
    creditRequestReason,
    decisionDate,
    id,
    repaymentTermDays,
    requestedCreditLimit,
    requester,
    reviewer,
    reviewerNotes,
    status
  },
  setFocusedCreditRequestCardId
}) => (
  <div className='mb-5 bg-lightgray border border-neutral rounded-lg shadow-navigate-cart'>
    <CardHead
      {...{
        createdAt,
        focusedCreditRequestCardId,
        id,
        requestedCreditLimit,
        requester,
        setFocusedCreditRequestCardId,
        status
      }}
    />
    {focusedCreditRequestCardId === id && (
      <>
        <CardBody
          {...{
            approvedCreditLimit,
            requestedCreditLimit,
            requester,
            reviewer,
            reviewerNotes
          }}
        />
        <CardFooter
          {...{ creditRequestReason, decisionDate, repaymentTermDays }}
        />
      </>
    )}
  </div>
);

export default CreditEntityCreditHistoryCard;
