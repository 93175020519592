import Image from 'next/image';
import { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';

import { ImageCarouselModal } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import {
  downloadImage,
  getImageURL,
  getRankedImages
} from '@/helpers/carousel';

const CloseIcon = ({ setOpenImageGalleryModal }) => (
  <span className='flex justify-end cursor-pointer'>
    <Image
      {...{
        alt: 'cross',
        height: 0,
        onClick: () => setOpenImageGalleryModal(false),
        src: `${staticMediaStoreBaseURL}/icons/close-with-border-gray.svg`,
        style: { height: 20, width: 20 },
        width: 0
      }}
    />
  </span>
);

const MediaGridItem = ({ media, openImageModal }) => {
  const actionIconCommonAttributes = {
    className: 'cursor-pointer',
    iconHeight: 20,
    iconWidth: 20
  };

  const actionIconAttributes = [
    {
      ...actionIconCommonAttributes,
      alt: 'download product image',
      icon: 'download-white-icon-v1.svg',
      onClick: () => {
        if (!media.defaultImage) {
          downloadImage({
            imageUrl: getImageURL(media),
            filename: media.filename || media.url.replaceAll('/', '_')
          });
        }
      }
    },
    {
      ...actionIconCommonAttributes,
      alt: 'view',
      icon: 'view-icon.svg',
      onClick: () => openImageModal(media)
    }
  ];

  return (
    <div className='relative planner-cart-gallery-box rounded-xl border border-platinum'>
      <Image
        {...{
          alt: 'planner-cart-gallery',
          className: 'rounded-xl',
          fill: true,
          objectFit: 'contain',
          src: getImageURL(media)
        }}
      />
      <div className='absolute black-overlayer w-full h-full rounded-xl z-10 flex items-center justify-center gap-3'>
        {actionIconAttributes.map(
          (
            { alt, className, iconHeight, onClick, icon, iconWidth },
            index
          ) => (
            <Image
              key={index}
              {...{
                alt,
                className,
                height: 0,
                onClick,
                src: `${staticMediaStoreBaseURL}/icons/${icon}`,
                style: { height: iconHeight, width: iconWidth },
                width: 0
              }}
            />
          )
        )}
      </div>
    </div>
  );
};

const MediaGrid = ({ mediaList = [], openImageModal }) =>
  mediaList.map((media, index) => (
    <MediaGridItem
      key={index}
      {...{
        media,
        openImageModal
      }}
    />
  ));

const ImageGalleryModal = ({
  itemMediaForCarousel,
  productName,
  setOpenImageGalleryModal
}) => {
  const [modalImage, setModalImage] = useState(false);

  const openImageModal = (image) => {
    setModalImage(image);
  };

  const images = getRankedImages(itemMediaForCarousel);

  return (
    <Modal
      backdrop={true}
      centered
      fade={false}
      isOpen={true}
      size='lg'
      toggle={() => setOpenImageGalleryModal(false)}
    >
      <ModalBody>
        <div className='m-6 bg-white rounded-xl px-6 pb-6'>
          <div className='flex justify-between pt-7 mb-4'>
            <div className='text-xl font-medium text-nero'>{productName}</div>
            <CloseIcon {...{ setOpenImageGalleryModal }} />
          </div>
          <div className='h-80vh'>
            <div className='text-base font-medium text-dim-gray mt-6 mb-4'>
              Existing Media
            </div>
            <div className='flex flex-wrap gap-4'>
              {itemMediaForCarousel && (
                <MediaGrid
                  {...{
                    mediaList: itemMediaForCarousel,
                    openImageModal
                  }}
                />
              )}
            </div>
          </div>
          {modalImage && (
            <ImageCarouselModal
              {...{
                images,
                modalImage,
                openImageModal
              }}
            />
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ImageGalleryModal;
