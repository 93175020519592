import {
  EPICExplanationWithoutTooltip,
  TextWithIcon,
  Tooltip
} from '@/components/atomic/nuclei';
import { convertToShortMonthDateFormat } from '@/lib/time';

const EPICExplanation = ({ epicParameters }) => {
  const {
    city,
    eventDate,
    eventType,
    guests,
    hostCreditEntityName,
    userEventId,
    verticals
  } = epicParameters;

  const tooltipConfigListItems = [
    {
      alt: 'host credit entity white icon',
      className: 'shadow-card py-1 px-2 rounded',
      icon: 'tab-host-credit-entity.svg',
      label: hostCreditEntityName
    },
    {
      alt: 'calendar white icon',
      className: 'shadow-card py-1 px-2 rounded',
      icon: 'date-white-icon.svg',
      label: convertToShortMonthDateFormat(eventDate)
    },
    {
      alt: 'user event white icon',
      className: 'shadow-card py-1 px-2 rounded',
      icon: 'user-event-white-icon.svg',
      label: eventType
    },
    {
      alt: 'location white icon',
      className: 'shadow-card py-1 px-2 rounded',
      icon: 'location-white-icon.svg',
      label: city
    },
    {
      alt: 'guests white icon',
      className: 'shadow-card py-1 px-2 rounded',
      icon: 'guests-white-icon.svg',
      label: guests
    },
    {
      alt: 'core verticals white icon',
      className: 'shadow-card py-1 px-2 rounded',
      icon: 'core-verticals-white-icon.svg',
      label: verticals
    }
  ];

  const tooltipConfigList = [
    {
      target: `epic-explanation-${userEventId}`,
      text: tooltipConfigListItems.map((tooltipConfigListItem, index) => (
        <TextWithIcon
          key={`${tooltipConfigListItem.label}-${index}`}
          {...{
            ...tooltipConfigListItem,
            iconHeight: 16,
            iconWidth: 16,
            labelStyle: 'text-xs font-medium text-white'
          }}
        />
      ))
    }
  ];

  return (
    <>
      <EPICExplanationWithoutTooltip
        {...{
          className: 'text-xs md:leading-6 leading-[16px]',
          epicParameters,
          id: `epic-explanation-${userEventId}`
        }}
      />
      <Tooltip {...{ configList: tooltipConfigList }} />
    </>
  );
};

export default EPICExplanation;
