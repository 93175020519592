import Image from 'next/image';
import { useCallback, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useConnector } from 'react-instantsearch';

import { staticMediaStoreBaseURL } from '@/config/common';
import {
  getEndOfDayUnixTimestampFromDate,
  getStartOfDayUnixTimestampFromDate
} from '@/lib/time';

const DatePickerWithLabel = ({ datePickerProps, label }) => {
  const labelContentRef = useRef(null);

  const onClickLabel = useCallback((event) => {
    if (event.nativeEvent.target !== labelContentRef.current) {
      event.preventDefault();
    }
  }, []);

  return (
    <div className='-my-1'>
      <span
        className='text-sm font-medium w-12 inline-block'
        ref={labelContentRef}
      >
        {label}
      </span>
      <label
        className='relative order-date-picker'
        onClick={onClickLabel}
      >
        <ReactDatePicker {...datePickerProps} />
        <span className='relative left-1 w-5 -top-[26px] justify-start flex'>
          <Image
            alt='date brand icon'
            height={0}
            src={`${staticMediaStoreBaseURL}/icons/date-brand-icon.svg`}
            style={{ height: 20, width: 20 }}
            width={0}
          />
        </span>
      </label>
    </div>
  );
};

const CustomDateRangePicker = ({
  endDate,
  refine,
  setEndDate,
  setStartDate,
  startDate
}) => {
  const [range, setRange] = useState({ startDate, endDate });

  const handleDateChange = async ({
    date,
    dateStateToUpdate,
    rangeStateToUpdate,
    refineToUpdate
  }) => {
    dateStateToUpdate(date);
    setRange((prevRange) => ({
      ...prevRange,
      ...rangeStateToUpdate
    }));
    await refine(refineToUpdate);
  };

  const commonDatePickerProps = {
    dateFormat: 'do MMM yyyy',
    endDate,
    startDate
  };

  return (
    <div className='searchkit-datepicker mt-4 flex flex-col'>
      <DatePickerWithLabel
        {...{
          datePickerProps: {
            ...{
              ...commonDatePickerProps,
              maxDate: range.endDate,
              onChange: (date) =>
                handleDateChange({
                  date,
                  dateStateToUpdate: setStartDate,
                  rangeStateToUpdate: { startDate: date },
                  refineToUpdate: { endDate: range.endDate, startDate: date }
                }),
              placeholderText: 'Select Start Date',
              selected: startDate,
              selectsStart: true
            }
          },
          label: 'From: '
        }}
      />
      <DatePickerWithLabel
        {...{
          datePickerProps: {
            ...{
              ...commonDatePickerProps,
              minDate: range.startDate,
              onChange: (date) =>
                handleDateChange({
                  date,
                  dateStateToUpdate: setEndDate,
                  rangeStateToUpdate: { endDate: date },
                  refineToUpdate: { endDate: date, startDate: range.startDate }
                }),
              placeholderText: 'Select End Date',
              selected: endDate,
              selectsEnd: true
            }
          },
          label: 'To: '
        }}
      />
    </div>
  );
};

const noop = () => {};

const connectDateRefinementList = (renderFn, unmountFn = noop) =>
  function dateRangeRefinementList(widgetParams) {
    const { attribute } = widgetParams;
    const connectorState = {};
    return {
      $$type: 'planner.dateRangeRefinementList',
      getWidgetRenderState({ results, helper }) {
        if (!connectorState.refine) {
          connectorState.refine = (value) => {
            const algoliaSearchHelper = helper.clearRefinements(attribute);
            if (value.startDate) {
              algoliaSearchHelper.addNumericRefinement(
                attribute,
                '>=',
                getStartOfDayUnixTimestampFromDate(value.startDate)
              );
            }
            if (value.endDate) {
              algoliaSearchHelper.addNumericRefinement(
                attribute,
                '<=',
                getEndOfDayUnixTimestampFromDate(value.endDate)
              );
            }
            return algoliaSearchHelper.search();
          };
        }
        if (!results) {
          return { items: [], refine: connectorState.refine, widgetParams };
        }
        const items = results.getFacetValues(attribute) || [];
        return {
          items,
          refine: connectorState.refine,
          widgetParams
        };
      },
      init(initOptions) {
        const { instantSearchInstance } = initOptions;
        renderFn(
          {
            ...this.getWidgetRenderState(initOptions),
            instantSearchInstance
          },
          true
        );
      },
      render(renderOptions) {
        const { instantSearchInstance } = renderOptions;
        renderFn(
          {
            ...this.getWidgetRenderState(renderOptions),
            instantSearchInstance
          },
          false
        );
      },
      dispose() {
        unmountFn();
      }
    };
  };

const useDateRangeRefinementWidget = (props) =>
  useConnector(connectDateRefinementList, props, {
    $$widgetType: 'planner.dateRangeRefinementList'
  });

const DateRangeFilterWithDatePicker = (props) => {
  const widgetRenderStateData = useDateRangeRefinementWidget(props);
  return (
    <div className='searchkit-datepicker-container'>
      <CustomDateRangePicker {...{ ...widgetRenderStateData, ...props }} />
    </div>
  );
};

export default DateRangeFilterWithDatePicker;
