import { CloseIcon, Text } from '@/components/atomic/nuclei';

const HeaderWithNavigator = ({ formStep, onClickExit }) => (
  <>
    <div className='flex items-center justify-between border-b border-neutral px-6 py-4'>
      <Text
        {...{
          className: 'text-lg font-semibold text-nero',
          content: 'Create New Event'
        }}
      />
      <CloseIcon
        {...{
          height: 40,
          icon: 'close-brand-icon.svg',
          onClick: onClickExit,
          width: 40
        }}
      />
    </div>
    <div className='flex justify-center gap-2 my-4'>
      <div className='w-20 h-1 bg-brand-gradient rounded-full'></div>
      <div
        className={`w-20 h-1 rounded-full ${
          formStep === 1 ? 'bg-brand-gradient' : 'bg-neutral'
        }`}
      ></div>
    </div>
  </>
);

export default HeaderWithNavigator;
