import Image from 'next/image';

import { CTA, Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';

const SectionHeadingSaveDiscardCTA = ({
  onClickDiscardChanges,
  onClickSaveChanges
}) => {
  const saveDiscardCTAList = [
    {
      buttonColor: 'bg-gray',
      label: 'Cancel',
      onClick: onClickDiscardChanges
    },
    {
      buttonColor:
        'bg-gradient-to-r from-light-orange via-dark-orange to-rose text-white',
      label: 'Save',
      onClick: onClickSaveChanges
    }
  ];
  return (
    <div className='flex justify-end gap-4 self-center pr-3'>
      {saveDiscardCTAList.map(({ buttonColor, label, onClick }) => (
        <CTA
          key={label}
          {...{
            buttonColor,

            className: 'py-1 h-9 text-sm font-medium px-6 py-2',
            label,
            onClick
          }}
        />
      ))}
    </div>
  );
};

const SectionHeadingViewMode = ({ onClickEdit, title, isCartEditable }) => (
  <div className='flex gap-3'>
    <Text {...{ content: title, className: 'text-lg font-medium' }} />
    {isCartEditable && (
      <div className='w-6 h-6 flex justify-center self-center bg-neutral rounded-full'>
        <Image
          alt='edit'
          className='self-center cursor-pointer'
          height={0}
          onClick={onClickEdit}
          src={`${staticMediaStoreBaseURL}/icons/edit-brand-icon.svg`}
          style={{ height: 12, width: 12 }}
          width={0}
        />
      </div>
    )}
  </div>
);

const SectionHeadingEditMode = ({
  onClickDiscardChanges,
  onClickSaveChanges,
  title
}) => (
  <>
    <div className='flex gap-3'>
      <Text {...{ className: 'text-lg font-medium', content: title }} />
    </div>
    <SectionHeadingSaveDiscardCTA
      {...{ onClickDiscardChanges, onClickSaveChanges }}
    />
  </>
);

const SectionHeadingMap = {
  VIEW: SectionHeadingViewMode,
  EDIT: SectionHeadingEditMode
};

const CartSectionHeading = ({
  isCartEditable,
  mode,
  onClickDiscardChanges,
  onClickEdit,
  onClickSaveChanges,
  title
}) => {
  const SectionHeading = SectionHeadingMap[mode];
  return (
    <div className='flex justify-between'>
      <SectionHeading
        {...{
          isCartEditable,
          onClickDiscardChanges,
          onClickEdit,
          onClickSaveChanges,
          title
        }}
      />
    </div>
  );
};

export default CartSectionHeading;
