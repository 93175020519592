import Image from 'next/image';
import { Modal, ModalBody } from 'reactstrap';

import { staticMediaStoreBaseURL } from '@/config/common';
import { downloadImage, getImageURL } from '@/helpers/carousel';

const MediaGridItem = ({ media, openImageModal }) => (
  <div className='relative planner-cart-gallery-box rounded-xl cursor-pointer border border-platinum'>
    <Image
      alt='planner-cart-gallery'
      className='rounded-xl object-contain'
      fill={true}
      src={getImageURL(media)}
    />
    <div className='absolute black-overlayer w-full h-full rounded-xl z-10 flex items-center justify-center gap-3'>
      <Image
        alt='download image'
        className='cursor-pointer'
        height={0}
        onClick={() =>
          downloadImage({
            imageUrl: getImageURL(media),
            filename: media.filename || media.url.replaceAll('/', '_')
          })
        }
        src={`${staticMediaStoreBaseURL}/icons/download-white-icon-v1.svg`}
        style={{ height: 20, width: 20 }}
        width={0}
      />
      <Image
        alt='view'
        className='cursor-pointer'
        height={0}
        onClick={() => openImageModal(media)}
        src={`${staticMediaStoreBaseURL}/icons/view-icon.svg`}
        style={{ height: 20, width: 20 }}
        width={0}
      />
    </div>
  </div>
);

const MediaGrid = ({ mediaList = [], openImageModal }) =>
  mediaList.map((media, index) => (
    <MediaGridItem
      key={index}
      {...{
        media,
        openImageModal
      }}
    />
  ));
const CloseIcon = ({ setShowImageGalleryModal }) => (
  <span className='flex justify-end cursor-pointer'>
    <Image
      alt='cross'
      height={0}
      onClick={() => setShowImageGalleryModal(false)}
      src={`${staticMediaStoreBaseURL}/icons/close-with-border-gray.svg`}
      style={{ height: 20, width: 20 }}
      width={0}
    />
  </span>
);

const UserEventGalleryModal = ({
  mediaList,
  openImageModal,
  setShowImageGalleryModal,
  showImageGalleryModal
}) => (
  <Modal
    centered
    size='lg'
    isOpen={showImageGalleryModal}
    backdrop={true}
    fade={false}
  >
    <ModalBody>
      <div className='m-6 bg-white rounded-xl px-6 pb-6'>
        <div className='flex justify-between pt-7 mb-4'>
          <div className='text-xl font-medium text-nero'>{'Event Media'}</div>
          <CloseIcon {...{ setShowImageGalleryModal }} />
        </div>
        <div className='h-80vh'>
          <div className='flex flex-wrap gap-4'>
            <MediaGrid
              {...{
                mediaList,
                openImageModal
              }}
            />
          </div>
        </div>
      </div>
    </ModalBody>
  </Modal>
);

export default UserEventGalleryModal;
