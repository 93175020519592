import Image from 'next/image';
import { useEffect, useState } from 'react';

import {
  Button,
  Text,
  TextWithIcon,
  Tooltip
} from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import { DELAY_SEARCHKIT_RENDER_TIME } from '@/helpers/searchkit';

const HoverCard = ({ hoverCard: { cta, textWithIconList } }) => (
  <div className='border-t border-neutral w-full flex justify-between gap-4 py-1 list-card-footer'>
    <div className='flex gap-4'>
      {textWithIconList.map(
        (
          {
            additionalLabelStyle = '',
            alt,
            clipboardText,
            icon,
            id = '',
            label,
            onClick = () => {},
            onHoverCopy = false,
            tooltipText = ''
          },
          index
        ) => (
          <TextWithIcon
            key={index}
            {...{
              alt,
              className: 'px-2 py-1',
              clipboardText,
              icon,
              iconHeight: 20,
              iconWidth: 20,
              id,
              label,
              labelStyle: `text-sm text-nero font-light ${additionalLabelStyle}`,
              onClick,
              onHoverCopy,
              show: true,
              tooltipText
            }}
          />
        )
      )}
    </div>
    {cta && (
      <Button
        {...{
          className:
            'rounded-lg py-[4px] text-sm font-medium border border-brand mr-2 text-brand',
          iconHeight: 20,
          iconPosition: 'Left',
          iconURL: `${staticMediaStoreBaseURL}/icons/modal-open-icon-red.svg`,
          iconWidth: 20,
          label: `${cta.label}`,
          onClick: cta.onClick,
          width: 'w-36'
        }}
      />
    )}
  </div>
);

const CardHead = ({
  header: {
    onHoverTitleSubscriptCopy = false,
    subTitle: {
      additionalLabelStyle = '',
      alt: subTitleAlt,
      clipboardText,
      icon: subTitleIcon,
      id: subTitleId,
      isBorder,
      onClick: onSubTitleClick = () => {},
      onHoverCopy: onHoverCopySubTitle = false,
      tooltipText: subTitleTooltipText = '',
      value: subTitleValue
    } = {},
    title,
    titleSubscript,
    titleSubscriptId,
    titleSubscriptTooltipText
  }
}) => (
  <div className='flex-col'>
    <div className='flex items-center gap-3'>
      <Text
        {...{
          className: 'text-nero text-xl font-medium max-w-[30rem] truncate',
          content: title
        }}
      />
      <div className='border-l-2 border-neutral h-4'></div>
      <TextWithIcon
        {...{
          alt: subTitleAlt,
          className: `${
            isBorder ? 'border border-neutral rounded' : ''
          } px-2 py-1`,
          clipboardText,
          icon: subTitleIcon,
          iconHeight: 20,
          iconWidth: 20,
          id: subTitleId,
          label: subTitleValue,
          labelStyle: `text-sm text-nero font-light ${additionalLabelStyle}`,
          onClick: onSubTitleClick,
          onHoverCopy: onHoverCopySubTitle,
          tooltipText: subTitleTooltipText
        }}
      />
    </div>
    {titleSubscript && (
      <Text
        {...{
          className: 'text-sm text-nero font-light mt-1 w-fit',
          content: titleSubscript,
          id: titleSubscriptId,
          onHoverCopy: onHoverTitleSubscriptCopy,
          tooltipText: titleSubscriptTooltipText
        }}
      />
    )}
  </div>
);

const CardBody = ({
  body: {
    entityNumber: {
      additionalLabelStyle = '',
      additionalTooltipClass = '',
      alt: entityNumberAlt,
      clipboardText,
      icon: entityNumberIcon,
      id: entityNumberId,
      label: entityNumberLabel,
      onClick: onClickEntityNumber = () => {},
      onHoverCopy: onHoverCopyEntityNumber = false,
      tooltipText: entityNumberTooltipText = ''
    },
    entityStatus: { id: entityStatusId, statusClass, value } = {}
  }
}) => (
  <div className='flex gap-4 ml-auto'>
    {value && (
      <Text
        {...{
          className: `${statusClass} px-2 py-1 self-start rounded text-sm font-medium`,
          content: value,
          id: entityStatusId
        }}
      />
    )}
    <TextWithIcon
      {...{
        additionalTooltipClass,
        alt: entityNumberAlt,
        className: 'border border-neutral rounded px-2 py-1',
        clipboardText,
        icon: entityNumberIcon,
        iconHeight: 20,
        iconWidth: 20,
        id: entityNumberId,
        label: entityNumberLabel,
        labelStyle: `text-sm text-dim-gray font-medium ${additionalLabelStyle}`,
        onClick: onClickEntityNumber,
        onHoverCopy: onHoverCopyEntityNumber,
        tooltipText: entityNumberTooltipText
      }}
    />
  </div>
);

const MainCard = ({
  body,
  header,
  icon,
  tooltipConfigListForMainCard = []
}) => (
  <div className='flex flex-1 gap-4 p-3'>
    <Image
      alt={icon.alt}
      height={0}
      priority={true}
      src={`${staticMediaStoreBaseURL}/icons/${icon.name}`}
      style={{
        height: 52,
        width: 52
      }}
      width={0}
    />
    <CardHead {...{ header }} />
    <CardBody {...{ body }} />
    <Tooltip {...{ configList: tooltipConfigListForMainCard }} />
  </div>
);

const ListCard = ({
  additionalLoaderClass = 'justify-center',
  listCard: {
    body,
    header,
    hoverCard,
    icon,
    onClick,
    tooltipConfigListForMainCard
  }
}) => {
  const [displayResults, setDisplayResults] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplayResults(true);
    }, DELAY_SEARCHKIT_RENDER_TIME);
    return () => clearTimeout(timer);
  }, []);

  return displayResults ? (
    <div
      className={
        'cursor-pointer border-neutral hover:border-brand border rounded-lg flex flex-col w-full hover:shadow-product-card list-card'
      }
      onClick={onClick}
    >
      <MainCard {...{ body, header, icon, tooltipConfigListForMainCard }} />
      {hoverCard && <HoverCard {...{ hoverCard }} />}
    </div>
  ) : (
    <div className='fixed inset-0 bg-white overflow-hidden'>
      <div className={`loading-bg h-screen flex ${additionalLoaderClass}`}>
        <Image
          alt='loading image'
          className='self-center w-12'
          height='0'
          priority={true}
          src={`${staticMediaStoreBaseURL}/icons/loading.gif`}
          style={{ height: 20, width: 20 }}
          width='0'
        />
      </div>
    </div>
  );
};

export default ListCard;
