import _ from 'lodash';

import {
  HOST_INTERACTION_STATUS,
  HOST_TYPE,
  ORDER_STATUS
} from '@/config/common';
import {
  CART_STATUS,
  DATE_FILTER_ATTRIBUTE,
  TRANSFORMED_USER_EVENT_STAGE
} from '@/config/searchkit/navigation';
import {
  getFormattedDateFromUnixTimestamp,
  getUnixTimestampForOffsetDate
} from '@/lib/time';

const ALL_STATUS = {
  ...ORDER_STATUS,
  ...CART_STATUS
};

const refinementKeyValueLabelMap = {
  'categoryLevels.level_0': {
    keyLabel: 'Category'
  },
  'price.sortPrice': {
    keyLabel: 'Price Range'
  },
  'productOrderStatistics.userEventCount': {
    keyLabel: 'Event Count'
  },
  'productPartners.partner.legalName': {
    keyLabel: 'Partners'
  },
  [DATE_FILTER_ATTRIBUTE.BOOKING_DATE]: {
    getValueLabel: (refinement) =>
      `${refinement.operator} ${getFormattedDateFromUnixTimestamp({
        dateFormat: 'Do MMM YYYY',
        unixTimestamp: refinement.value
      })}`,
    keyLabel: 'Booking Date'
  },
  [DATE_FILTER_ATTRIBUTE.EVENT_DATE]: {
    getValueLabel: (refinement) =>
      `${refinement.operator} ${getFormattedDateFromUnixTimestamp({
        dateFormat: 'Do MMM YYYY',
        unixTimestamp: refinement.value
      })}`,
    keyLabel: 'Event Date'
  },
  [DATE_FILTER_ATTRIBUTE.CREATED_AT_DATE]: {
    getValueLabel: (refinement) =>
      `${refinement.operator} ${getFormattedDateFromUnixTimestamp({
        dateFormat: 'Do MMM YYYY',
        unixTimestamp: refinement.value
      })}`,
    keyLabel: 'Created At Date'
  },
  hostSegment: {
    getValueLabel: ({ value }) => HOST_TYPE[value]?.label,
    keyLabel: 'Host Type'
  },
  interactionStatus: {
    getValueLabel: ({ value }) => HOST_INTERACTION_STATUS[value]?.label,
    keyLabel: 'Interaction Status'
  },
  isDiscountAvailable: {
    getValueLabel: () => 'Yes',
    keyLabel: 'Discount Available'
  },
  stage: {
    getValueLabel: ({ value }) => TRANSFORMED_USER_EVENT_STAGE[value]?.label,
    keyLabel: 'Event Stage'
  },
  status: {
    getValueLabel: ({ value }) => ALL_STATUS[value]?.label,
    keyLabel: 'Status'
  }
};

const transformCurrentRefinementItemsLabel = (items) =>
  items.map((item) => {
    const {
      keyLabel = _.startCase(item.label),
      getValueLabel = (refinement) => refinement.label
    } = refinementKeyValueLabelMap[item.attribute] || {};

    const refinements = item.refinements.map((refinement) => ({
      ...refinement,
      label: getValueLabel(refinement)
    }));

    return { ...item, label: keyLabel, refinements };
  });

export default transformCurrentRefinementItemsLabel;

// TODO: sidd - un-used: skip from knip survey, TBD if to delete
export const getMenuFromCurrentURL = ({
  attribute,
  index,
  instantSearchRouter,
  setSelectedTab
}) => {
  const urlParams = new URLSearchParams(
    instantSearchRouter.getLocation().search
  );
  const menu = urlParams.get(`${index}[menu][${attribute}]`);
  setSelectedTab(menu);
};

export const getCountForAttribute = ({ attribute, currentRefinements }) => {
  const { refinements: attributeRefinements = [] } =
    currentRefinements.find(
      (refinement) => refinement.attribute === attribute
    ) || {};
  return attributeRefinements.length;
};

export const GENERIC_DATE_RANGE_ITEMS = {
  ALL: {
    key: 'all',
    label: 'All'
  },
  PAST: {
    end: getUnixTimestampForOffsetDate({ dateOffset: -1 }),
    key: 'past',
    label: 'Past'
  },
  UPCOMING: {
    key: 'upcoming',
    label: 'Upcoming',
    start: getUnixTimestampForOffsetDate({ dateOffset: 1 })
  },
  TODAY: {
    end: getUnixTimestampForOffsetDate({ dateOffset: 0, endOfDay: true }),
    key: 'today',
    label: 'Today',
    start: getUnixTimestampForOffsetDate({ dateOffset: 0 })
  },
  TOMORROW: {
    end: getUnixTimestampForOffsetDate({ dateOffset: 1, endOfDay: true }),
    key: 'tomorrow',
    label: 'Tomorrow',
    start: getUnixTimestampForOffsetDate({ dateOffset: 1 })
  },
  YESTERDAY: {
    end: getUnixTimestampForOffsetDate({ dateOffset: -1, endOfDay: true }),
    key: 'yesterday',
    label: 'Yesterday',
    start: getUnixTimestampForOffsetDate({ dateOffset: -1 })
  }
};

export const DATE_RANGE_ITEMS_FOR_EVENT_DATE = {
  ALL: GENERIC_DATE_RANGE_ITEMS.ALL,
  PAST: GENERIC_DATE_RANGE_ITEMS.PAST,
  UPCOMING: GENERIC_DATE_RANGE_ITEMS.UPCOMING,
  TODAY: GENERIC_DATE_RANGE_ITEMS.TODAY,
  TOMORROW: GENERIC_DATE_RANGE_ITEMS.TOMORROW,
  YESTERDAY: GENERIC_DATE_RANGE_ITEMS.YESTERDAY
};

export const DATE_RANGE_ITEMS_FOR_CART_EVENT_DATE = {
  ALL: GENERIC_DATE_RANGE_ITEMS.ALL,
  EXECUTED: GENERIC_DATE_RANGE_ITEMS.PAST,
  UPCOMING: GENERIC_DATE_RANGE_ITEMS.UPCOMING,
  TODAY: GENERIC_DATE_RANGE_ITEMS.TODAY,
  TOMORROW: GENERIC_DATE_RANGE_ITEMS.TOMORROW
};

export const DATE_RANGE_ITEMS_FOR_USER_EVENT_DATE = {
  ALL: GENERIC_DATE_RANGE_ITEMS.ALL,
  PAST: GENERIC_DATE_RANGE_ITEMS.PAST,
  UPCOMING: GENERIC_DATE_RANGE_ITEMS.UPCOMING,
  TODAY: GENERIC_DATE_RANGE_ITEMS.TODAY,
  TOMORROW: GENERIC_DATE_RANGE_ITEMS.TOMORROW,
  YESTERDAY: GENERIC_DATE_RANGE_ITEMS.YESTERDAY
};

const getFacetFilterValue = ({ url, key }) => {
  const searchParams = new URLSearchParams(url);
  const facetFiltersValue = searchParams.get('facetFilters');
  if (!facetFiltersValue) {
    return null;
  }

  const keyValuePairs = facetFiltersValue
    .split(',')
    .map((pair) => pair.split(':'));
  const value = _.chain(keyValuePairs)
    .find(([currentKey]) => currentKey === key)
    .last()
    .value();
  return value;
};

export const DEFAULT_NUMERIC_REFINEMENT = encodeURI('{}');

export const DELAY_SEARCHKIT_RENDER_TIME = 1000;

const transformRadioInput = ({ attribute, e, items, values }) =>
  Object.values(values)
    .map(({ label, sortOrder, value: valueToInspect }) => {
      const itemsOfValueToInspect = items.find(
        ({ value }) => valueToInspect === value
      );
      const isRefined =
        getFacetFilterValue({
          key: attribute,
          url: e.results.params
        }) === valueToInspect;

      const RadioInputLabel = () => (
        <label className='searchkit-filter-menu-item searchkit-order-status-filter-menu searchkit-delivery-menu-item relative cursor-pointer'>
          <input
            className='checked:content-searchkit-filter-menu-item-checked searchkit-radio searchkit-filter-menu-item-checked'
            defaultChecked={isRefined}
            name={attribute}
            type='radio'
            value={valueToInspect}
          />
          <span className='before:bg-searchkit-filter-menu-item inline-block w-5 h-5 empty-radio-span'></span>
          <span className='text-sm text-dim-gray pl-4 self-center'>
            {label}
          </span>
        </label>
      );

      const defaultBucket = {
        count: 0,
        data: null,
        exhaustive: true,
        isRefined,
        label: <RadioInputLabel />,
        sortOrder,
        value: valueToInspect
      };

      const transformedValue = {
        ...(itemsOfValueToInspect || {}),
        label: <RadioInputLabel />,
        sortOrder
      };
      return itemsOfValueToInspect ? transformedValue : defaultBucket;
    })
    .filter(Boolean)
    .sort((a, b) => a.sortOrder - b.sortOrder)
    .map((a) => _.omit(a, 'sortOrder'));

export const transformOrderStatusRadio =
  ({ attribute }) =>
  (props, e) =>
    transformRadioInput({ attribute, e, items: props, values: ORDER_STATUS });

export const transformCartStatusRadio =
  ({ attribute }) =>
  (props, e) =>
    transformRadioInput({ attribute, e, items: props, values: CART_STATUS });

export const transformHostInteractionStatusRadio =
  ({ attribute }) =>
  (props, e) =>
    transformRadioInput({
      attribute,
      e,
      items: props,
      values: HOST_INTERACTION_STATUS
    });

export const transformHostTypeRadio =
  ({ attribute }) =>
  (props, e) =>
    transformRadioInput({
      attribute,
      e,
      items: props,
      values: HOST_TYPE
    });

export const transformUserEventStageRadio =
  ({ attribute }) =>
  (props, e) =>
    transformRadioInput({
      attribute,
      e,
      items: props,
      values: TRANSFORMED_USER_EVENT_STAGE
    });
