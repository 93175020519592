import { Button } from '@/components/atomic/nuclei';
import { PRODUCT_STATUS, staticMediaStoreBaseURL } from '@/config/common';

const PublishedItemButton = ({ onClickAddToCart }) => (
  <Button
    {...{
      className:
        'text-white bg-gradient-to-r from-light-orange via-dark-orange to-rose py-2 h-12 rounded-lg text-sm font-medium',
      iconGroupClassName: 'reduce-gap',
      iconHeight: 18,
      iconPosition: 'Left',
      iconURL: `${staticMediaStoreBaseURL}/icons/cart-white-icon-v1.svg`,
      iconWidth: 18,
      label: 'Add to Cart',
      onClick: onClickAddToCart,
      type: 'submit'
    }}
  />
);
const UnPublishedItemButton = () => (
  <Button
    {...{
      className: `text-white bg-gradient-to-r from-light-orange via-dark-orange to-rose py-2 h-12 rounded-lg w-full text-sm font-medium opacity-50 cursor-not-allowed`,
      disabled: true,
      label: 'Unavailable'
    }}
  />
);

const AddToCartButtonType = {
  [PRODUCT_STATUS.PUBLISHED]: PublishedItemButton,
  [PRODUCT_STATUS.UNPUBLISHED]: UnPublishedItemButton
};

const AddToCart = ({ onClickAddToCart, productStatus }) => {
  const AddToCartButton = AddToCartButtonType[productStatus];

  return (
    <div className='flex flex-1'>
      <div className='w-full'>
        <AddToCartButton
          {...{
            onClickAddToCart
          }}
        />
      </div>
    </div>
  );
};

export default AddToCart;
