import { TextWithIcon } from '@/components/atomic/nuclei';

const SwipeableCardSlider = ({
  cardCount,
  currentCardIndex,
  onClickViewAllCTA = () => {},
  setCurrentCardIndex
}) => {
  const handleDotClick = (index) => {
    setCurrentCardIndex(index);
  };

  const ViewAllCTA = ({
    className = 'hidden',
    labelStyle = 'text-sm text-dim-gray font-medium',
    onClick = () => {}
  }) => (
    <TextWithIcon
      {...{
        className,
        iconSuffix: {
          alt: 'arrow down icon',
          icon: 'arrow-down-brand-icon.svg',
          iconStyle: '-rotate-90',
          iconHeight: 12,
          iconWidth: 12
        },
        label: 'View All',
        labelStyle,
        onClick
      }}
    />
  );

  return (
    <div className='md:hidden flex justify-center flex-row items-center self-center pt-[5px] swipeable-footer-view'>
      {cardCount > 1 && (
        <div className='flex justify-center gap-2 mt-2 flex-1 items-center pl-[40px]'>
          {Array.from({ length: cardCount }).map((_, dotIndex) => (
            <div
              key={dotIndex}
              onClick={() => handleDotClick(dotIndex)}
              className={`w-1.5 h-1.5 rounded-full cursor-pointer ${
                currentCardIndex === dotIndex
                  ? 'bg-brand-gradient !w-3'
                  : 'bg-platinum'
              }`}
            ></div>
          ))}
        </div>
      )}
      <ViewAllCTA
        {...{
          className: 'cursor-pointer py-1 px-1 rounded flex mt-[5px] !gap-1',
          labelStyle: 'text-xs text-brand font-medium leading-[12px]',
          onClick: onClickViewAllCTA
        }}
      />
    </div>
  );
};

export default SwipeableCardSlider;
