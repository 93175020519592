import { PropTypes } from 'prop-types';

import { Text, TextWithIcon } from '@/components/atomic/nuclei';

const EventOrderDetails = ({ userEventDetails }) => {
  const {
    userEventOrderDetailsBanner: {
      eventName: { id: eventId, value: eventName }
    },
    userEventOrderDetailsBody
  } = userEventDetails;

  return (
    <div className='flex flex-col gap-3 bg-lightgray border-b border-r rounded-b-lg border-l border-platinum text-rich-black md:p-6 p-4'>
      <div
        className='flex-col md:w-2/4'
        id={eventId}
      >
        <Text
          {...{
            className:
              'text-base md:text-xl font-medium text-rich-black cursor-pointer',
            content: eventName,
            onHoverCopy: true,
            tooltipText: 'Event Name'
          }}
        />
      </div>
      <div className='flex flex-col md:flex-row justify-between'>
        <div className='grid grid-cols-1 md:grid-cols-4 md:grid-rows-2 gap-2 mt-2 md:mt-0'>
          {userEventOrderDetailsBody.map(
            (
              {
                additionalLabelStyle,
                alt,
                className,
                icon,
                id = '',
                onClick,
                tooltipText,
                value
              },
              index
            ) => (
              <div
                className={`flex gap-3 md:gap-2 md:w-96 ${className}`}
                key={`${id}_${index}`}
              >
                <TextWithIcon
                  key={index}
                  {...{
                    alt,
                    className: 'flex',
                    icon,
                    iconHeight: 20,
                    iconWidth: 20,
                    id,
                    label: value,
                    labelStyle: `flex self-center text-sm md:text-base font-light text-nero max-w-52 truncate ${additionalLabelStyle}`,
                    onClick,
                    onHoverCopy: true,
                    tooltipText
                  }}
                />
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default EventOrderDetails;

EventOrderDetails.propTypes = {
  userEventDetails: PropTypes.object.isRequired
};
