import _ from 'lodash';

import { VIEW_EDIT_MODE } from '@/config/cart';
import {
  CART_ITEM_STATUS,
  CART_STATUS,
  PAYMENT_FLOW_SOURCE,
  PAYMENT_SOURCE_TYPE,
  PRODUCT_STATUS,
  PRODUCT_TYPE,
  VAT_PERCENTAGE
} from '@/config/common';
import {
  captureGTMEventPurchaseCompletion,
  captureGTMEventRemoveFromCart,
  updateGTMEventCartQuantity
} from '@/lib/gtm';
import {
  convertToLowerDenomination,
  formatToTwoDecimalString,
  parseFormatPriceNumberValueFromAPI,
  parseFormatPriceValueFromAPI
} from '@/lib/numberStringUtils';
import {
  convertToDateTimeString,
  convertToShortMonthDateFormat
} from '@/lib/time';
import { isEmptyOrNull } from '@/lib/utils';
import { OPS_PORTAL_URL } from '@/services/connections.service';
import { getWebQuotePageURL } from '@/services/hostPortal.service';
import * as HttpService from '@/services/http.service';
import { getOrderDetailsAPI } from '@/services/order.service';
import { generatePaymentLink } from '@/services/payment.service';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';
import { createTenderAndPublishBids } from '@/services/tender.service';
import {
  CLONE_CART_ITEM_API_URL,
  DELETE_CART_ITEM_MEDIA_API_URL,
  DELETE_ITEM_FROM_CART_API_URL,
  EVENT_CART_CHECKOUT_API_URL,
  GET_CART_DETAILS_API_URL,
  GET_IMAGEKIT_AUTH_API_URL,
  GET_READ_ONLY_CART_DETAILS_API_URL,
  RECONCILE_CART_ITEM_MEDIA_FROM_IMAGEKIT_API_URL,
  UPDATE_CART_INFO_API_URL,
  UPDATE_CART_ITEM_API_URL,
  UPDATE_CART_ITEM_MEDIA_SORT_ORDER_API_URL,
  UPDATE_CART_ITEM_SHOW_PRODUCT_MEDIA_API_URL,
  UPDATE_CART_ITEM_SORT_ORDER_API_URL
} from '@/services/url.service';

const environmentName = process.env.NEXT_PUBLIC_APP_ENV;

const getCartDetailsByIdAPI = async ({ cartId }) =>
  HttpService.getWithAuth(GET_CART_DETAILS_API_URL({ cartId }));

const getReadOnlyCartDetailsAPI = ({ cartId }) =>
  HttpService.getWithAuth(GET_READ_ONLY_CART_DETAILS_API_URL({ cartId }));

export const getViewProductOrderHistoryURL = ({ productId }) =>
  `${OPS_PORTAL_URL}/products/${productId}/edit/product-order-history`;

const updateCartInfoAPI = ({ cartId, data, userCartId }) =>
  HttpService.patchWithAuth(
    UPDATE_CART_INFO_API_URL({ cartId, userCartId }),
    data
  );

const deleteItemFromCartAPI = ({ cartItemId, cartId, userCartId }) =>
  HttpService.deleteWithAuth(
    DELETE_ITEM_FROM_CART_API_URL({ cartId, cartItemId, userCartId })
  );

const cloneCartItemAPI = ({ cartId, cartItemId, data, userCartId }) =>
  HttpService.postWithAuth(
    CLONE_CART_ITEM_API_URL({ userCartId, cartId, cartItemId }),
    data
  );

const updateCartItemAPI = ({ cartId, cartItemId, data, userCartId }) =>
  HttpService.postWithAuth(
    UPDATE_CART_ITEM_API_URL({ cartId, cartItemId, userCartId }),
    data
  );

const deleteCartItemMediaAPI = ({ cartId, cartItemId, cartItemMediaId }) =>
  HttpService.deleteWithAuth(
    DELETE_CART_ITEM_MEDIA_API_URL({ cartId, cartItemId, cartItemMediaId })
  );

const updateCartItemShowProductMediaToggleAPI = ({
  cartId,
  cartItemId,
  data,
  userCartId
}) =>
  HttpService.putWithAuth(
    UPDATE_CART_ITEM_SHOW_PRODUCT_MEDIA_API_URL({
      cartId,
      cartItemId,
      userCartId
    }),
    data
  );

export const getImageKitAuthAPI = () =>
  HttpService.getWithAuth(GET_IMAGEKIT_AUTH_API_URL());

export const reconcileCartItemMediaWithImageKitAPI = ({
  cartId,
  cartItemId
}) =>
  HttpService.postWithAuth(RECONCILE_CART_ITEM_MEDIA_FROM_IMAGEKIT_API_URL(), {
    cartId,
    cartItemId
  });

const updateCartItemSortOrderAPI = ({ cartId, data }) =>
  HttpService.postWithAuth(
    UPDATE_CART_ITEM_SORT_ORDER_API_URL({ cartId }),
    data
  );

const updateCartItemMediaSortOrderAPI = ({
  cartId,
  cartItemId,
  cartItemMediaToSort
}) =>
  HttpService.postWithAuth(
    UPDATE_CART_ITEM_MEDIA_SORT_ORDER_API_URL({ cartId, cartItemId }),
    { cartItemMediaToSort }
  );

const cartCheckoutAndBookOrderAPI = ({
  body,
  params: { cartId, userCartId }
}) =>
  HttpService.postWithAuth(
    EVENT_CART_CHECKOUT_API_URL({ cartId, userCartId }),
    body
  );

export const SECTION_LABELS_ENUM = {
  CART_ITEM_DELETE_MODE: 'CART_ITEM_DELETE_MODE',
  CART_ITEM: 'CART_ITEM',
  CART_NOTE: 'CART_NOTE',
  PRICE_SUMMARY: 'PRICE_SUMMARY'
};

export const defaultViewEditSectionLabels = {
  [SECTION_LABELS_ENUM.CART_ITEM_DELETE_MODE]: VIEW_EDIT_MODE.VIEW,
  [SECTION_LABELS_ENUM.CART_ITEM]: VIEW_EDIT_MODE.VIEW,
  [SECTION_LABELS_ENUM.CART_NOTE]: VIEW_EDIT_MODE.VIEW,
  [SECTION_LABELS_ENUM.PRICE_SUMMARY]: VIEW_EDIT_MODE.VIEW
};

export const defaultErrorMessagesOfSectionLabels = {
  [SECTION_LABELS_ENUM.CART_ITEM_DELETE_MODE]: '',
  [SECTION_LABELS_ENUM.CART_ITEM]: '',
  [SECTION_LABELS_ENUM.CART_NOTE]: '',
  [SECTION_LABELS_ENUM.PRICE_SUMMARY]: ''
};

const CartItemGroupTypeFilters = {
  CHECKOUT_READY: (items) =>
    items.filter(
      ({ status: cartItemStatus, product: { status: productStatus } }) =>
        productStatus === PRODUCT_STATUS.PUBLISHED &&
        cartItemStatus === CART_ITEM_STATUS.ACCEPTED.value
    ),
  CHECKOUT_STAGING: (items) =>
    items.filter(
      ({ status: cartItemStatus, product: { status: productStatus } }) =>
        productStatus === PRODUCT_STATUS.PUBLISHED &&
        [
          CART_ITEM_STATUS.REQUEST_PENDING.value,
          CART_ITEM_STATUS.REQUESTED.value
        ].includes(cartItemStatus)
    ),
  UNAVAILABLE: (items) =>
    items.filter(
      ({ status: cartItemStatus, product: { status: productStatus } }) =>
        cartItemStatus !== CART_ITEM_STATUS.ORDERED.value &&
        productStatus !== PRODUCT_STATUS.PUBLISHED
    ),
  ORDERED: (items) =>
    items.filter(
      ({ status: cartItemStatus }) =>
        cartItemStatus === CART_ITEM_STATUS.ORDERED.value
    )
};

export const filterItemsByCartItemGroupType = ({ items = [], type }) =>
  CartItemGroupTypeFilters[type](items);

export const getItemListGroups = (items) => ({
  checkoutReadyItems: filterItemsByCartItemGroupType({
    type: 'CHECKOUT_READY',
    items
  }),
  priceAwaitedCartItemList: filterItemsByCartItemGroupType({
    type: 'CHECKOUT_STAGING',
    items
  }),
  unavailableCartItemList: filterItemsByCartItemGroupType({
    type: 'UNAVAILABLE',
    items
  }),
  orderedCartItemList: filterItemsByCartItemGroupType({
    type: 'ORDERED',
    items
  })
});

const getGenericReducerCartItemField = (field) => (acc, cartItem) =>
  acc + parseFloat(cartItem[field] || 0);

export const getDeliveryChargeAmount = (cartItems) =>
  cartItems.reduce(getGenericReducerCartItemField('deliveryCharge'), 0);

export const getAdditionalChargeAmount = (cartItems) =>
  cartItems.reduce(getGenericReducerCartItemField('additionalCharge'), 0);

export const getProductPartnerListObject = ({ legalName, partnerId }) => [
  {
    label: legalName,
    value: partnerId
  }
];

export const getProductPartnerList = (productPartners) =>
  productPartners.map(({ partner: { legalName, id } }) => ({
    value: id,
    label: legalName
  }));

export const trimPercentSign = (str) => str.replace(/.%$/, '');

const getMarginPercentageValueForDisplay = ({
  unitPartnerPrice,
  unitSellingPrice
}) => {
  let marginPercentageValue = null;

  if (unitSellingPrice > 0) {
    marginPercentageValue = formatToTwoDecimalString({
      value: convertToLowerDenomination(
        (100 * (unitSellingPrice - unitPartnerPrice)) / unitSellingPrice
      )
    });
  }

  return marginPercentageValue ? `${marginPercentageValue} %` : '';
};

const convertValueFieldsToLowerDenomination = (itemDetail) => {
  const fieldsToUpdate = [
    'listedDiscount',
    'sellingPrice',
    'unitListedDiscount',
    'unitListedPrice',
    'unitPartnerPrice',
    'unitRackPrice',
    'unitSellingPrice'
  ];

  const updatedItemDetail = {};
  Object.keys(itemDetail).forEach((key) => {
    updatedItemDetail[key] = fieldsToUpdate.includes(key)
      ? convertToLowerDenomination(itemDetail[key])
      : itemDetail[key];

    if (key === 'haflaMarginPercent') {
      updatedItemDetail[key] = Number(trimPercentSign(itemDetail[key]));
    }
  });

  return updatedItemDetail;
};

export const onQuantityChange = ({
  getValues,
  inputtedQuantity,
  setValue
}) => {
  const currentUnitListedPrice = getValues('unitListedPrice');
  const currentUnitListedDiscount = getValues('unitListedDiscount');
  const currentUnitPartnerPrice = getValues('unitPartnerPrice');
  const computedListedPrice = inputtedQuantity * currentUnitListedPrice;
  const computedListedDiscount = inputtedQuantity * currentUnitListedDiscount;
  const computedTotalPartnerPrice = inputtedQuantity * currentUnitPartnerPrice;

  setValue('listedDiscount', computedListedDiscount);
  setValue('sellingPrice', computedListedPrice - computedListedDiscount);
  setValue('quantity', inputtedQuantity, {
    shouldValidate: true
  });
  setValue('partnerPrice', computedTotalPartnerPrice);
};

export const onProductPartnerSelectionChange = ({
  clearErrors,
  getValues,
  partnerId: selectedPartnerId,
  productPartners,
  setUpdatePartnerSelectionFlag,
  setValue,
  updatePartnerSelectionFlag
}) => {
  const currentQuantity = getValues('quantity');
  const currentUnitSellingPrice = getValues('unitSellingPrice');

  const { unitPartnerPrice: unitPartnerPriceValue } = productPartners.find(
    ({ partner: { id: partnerId } }) => selectedPartnerId === partnerId
  );

  setUpdatePartnerSelectionFlag(!updatePartnerSelectionFlag);
  setValue('partnerId', selectedPartnerId);
  setValue(
    'unitPartnerPrice',
    parseFormatPriceValueFromAPI(unitPartnerPriceValue)
  );
  setValue(
    'partnerPrice',
    parseFormatPriceValueFromAPI(unitPartnerPriceValue * currentQuantity)
  );
  setValue(
    'haflaMarginPercent',
    getMarginPercentageValueForDisplay({
      unitPartnerPrice: parseFormatPriceNumberValueFromAPI(
        unitPartnerPriceValue
      ),
      unitSellingPrice: currentUnitSellingPrice
    })
  );
  clearErrors();
};

export const onUnitPartnerPriceChange = ({
  getValues,
  inputtedUnitPartnerPrice,
  setValue
}) => {
  const currentQuantity = getValues('quantity');
  const currentUnitSellingPrice = getValues('unitSellingPrice');

  setValue(
    'haflaMarginPercent',
    getMarginPercentageValueForDisplay({
      unitPartnerPrice: inputtedUnitPartnerPrice,
      unitSellingPrice: currentUnitSellingPrice
    })
  );
  setValue('unitPartnerPrice', inputtedUnitPartnerPrice, {
    shouldValidate: true
  });
  setValue('partnerPrice', inputtedUnitPartnerPrice * currentQuantity);
};

export const onUnitListedPriceChange = ({
  getValues,
  inputtedUnitListedPrice,
  setValue
}) => {
  const currentQuantity = getValues('quantity');
  const currentUnitPartnerPrice = getValues('unitPartnerPrice');
  const computedListedPrice = inputtedUnitListedPrice * currentQuantity;

  setValue('unitListedDiscount', 0);
  setValue('listedDiscount', 0);
  setValue('sellingPrice', computedListedPrice);
  setValue('unitListedPrice', inputtedUnitListedPrice, {
    shouldValidate: true
  });
  setValue('unitSellingPrice', inputtedUnitListedPrice);
  setValue(
    'haflaMarginPercent',
    getMarginPercentageValueForDisplay({
      unitPartnerPrice: currentUnitPartnerPrice,
      unitSellingPrice: inputtedUnitListedPrice
    })
  );
};

const isAwaitingPriceFinalization = (itemStatus) =>
  ['REQUEST_PENDING', 'REQUESTED'].includes(itemStatus);

export const onChangeOfNotes = ({
  dbName,
  inputtedNotes,
  isPriceNote,
  itemStatus,
  setIsUnitListedPriceNotEditable,
  setValue
}) => {
  const checkForNotAwaitingPriceFinalization =
    !isAwaitingPriceFinalization(itemStatus);
  setValue(dbName, inputtedNotes, {
    shouldValidate: true
  });

  isPriceNote &&
    checkForNotAwaitingPriceFinalization &&
    setIsUnitListedPriceNotEditable(!inputtedNotes);
};

export const getSelectedPartner = ({ partnerId, productPartners }) => {
  const {
    partner: { legalName: selectedPartnerDisplayName }
  } = productPartners.find(
    ({ partnerId: partnerIdToInspect }) => partnerIdToInspect === partnerId
  );

  return selectedPartnerDisplayName;
};

export const getPartnerTaxDetailsForNonOrderedCart = (cartItems) => {
  const {
    taxableAmount: partnerTaxableAmount,
    nonTaxableAmount: partnerNonTaxableAmount
  } = cartItems.reduce(
    ({ taxableAmount, nonTaxableAmount }, cartItem) => {
      const {
        partnerId: selectedPartnerId,
        product: { productPartners: productPartnerList },
        quantity,
        unitPartnerPrice
      } = cartItem;

      const {
        unitPartnerPrice: defaultUnitPartnerPrice,
        partner: { partnerStatutory }
      } = productPartnerList.find(
        ({ partnerId }) => partnerId === selectedPartnerId
      );

      const applicableUnitPartnerPrice =
        unitPartnerPrice || defaultUnitPartnerPrice;
      const partnerPrice = applicableUnitPartnerPrice * quantity;

      let updatedTaxableAmount = taxableAmount;
      let updatedNonTaxableAmount = nonTaxableAmount;
      if (partnerStatutory?.vatApplicable) {
        updatedTaxableAmount += partnerPrice;
      } else {
        updatedNonTaxableAmount += partnerPrice;
      }

      return {
        taxableAmount: updatedTaxableAmount,
        nonTaxableAmount: updatedNonTaxableAmount
      };
    },
    { taxableAmount: 0, nonTaxableAmount: 0 }
  );

  const partnerVATAmount = (partnerTaxableAmount * VAT_PERCENTAGE) / 100;

  return {
    partnerPreVATAmount: partnerTaxableAmount + partnerNonTaxableAmount,
    partnerVATAmount
  };
};

export const getPartnerTaxDetailsForOrderedCart = (cartItems) => {
  const {
    taxableAmount: partnerTaxableAmount,
    nonTaxableAmount: partnerNonTaxableAmount
  } = cartItems.reduce(
    ({ taxableAmount, nonTaxableAmount }, cartItem) => {
      const {
        cartItemDerivedValue: {
          unitPartnerPrice,
          unitPartnerPrice: defaultUnitPartnerPrice,
          partnerVatApplicable
        },
        quantity
      } = cartItem;

      const applicableUnitPartnerPrice =
        unitPartnerPrice || defaultUnitPartnerPrice;
      const partnerPrice = applicableUnitPartnerPrice * quantity;

      let updatedTaxableAmount = taxableAmount;
      let updatedNonTaxableAmount = nonTaxableAmount;
      if (partnerVatApplicable) {
        updatedTaxableAmount += partnerPrice;
      } else {
        updatedNonTaxableAmount += partnerPrice;
      }

      return {
        taxableAmount: updatedTaxableAmount,
        nonTaxableAmount: updatedNonTaxableAmount
      };
    },
    { taxableAmount: 0, nonTaxableAmount: 0 }
  );

  const partnerVATAmount = (partnerTaxableAmount * VAT_PERCENTAGE) / 100;

  return {
    partnerPreVATAmount: partnerTaxableAmount + partnerNonTaxableAmount,
    partnerVATAmount
  };
};

export const getMenuOptions = ({
  bookOrderInactive,
  downloadQuoteInactive,
  onClickBidResponses,
  onClickBookOrder,
  onClickCopyWebQuoteLink,
  onClickDownloadQuote,
  onClickReorderItems,
  webQuoteLinkCTADisabled
}) => [
  {
    alt: 'cart items reorder icon',
    hoverIcon: 'reorder-brand-icon.svg',
    icon: 'reorder-gray-icon.svg',
    label: 'Reorder Items',
    onClick: onClickReorderItems,
    show: true
  },
  {
    alt: 'Bid response',
    hoverIcon: 'view-icon-with-frame-red.svg',
    icon: 'view-gray-icon.svg',
    label: 'Bids Responses',
    onClick: onClickBidResponses,
    show: true
  },
  {
    alt: 'Download Quote',
    hoverIcon: 'download-brand-icon.svg',
    icon: 'download-black-icon.svg',
    isInactive: downloadQuoteInactive,
    label: 'Download Quote',
    onClick: onClickDownloadQuote,
    show: true
  },
  {
    alt: 'copy web quote',
    hoverIcon: 'copy-brand-icon-v3.svg',
    icon: 'copy-black-icon.svg',
    isInactive: webQuoteLinkCTADisabled,
    label: 'Web Quote Link',
    onClick: onClickCopyWebQuoteLink,
    show: true
  },
  {
    alt: 'Book Order',
    hoverIcon: 'cart-brand-icon-v1.svg',
    icon: 'cart-gray-icon.svg',
    isInactive: bookOrderInactive,
    label: 'Book Order',
    onClick: onClickBookOrder,
    show: true
  }
];

export const copyWebQuoteLink = async ({
  cartId,
  copyToClipBoard,
  setShowToast
}) => {
  const isClipboardAvailable = Boolean(navigator.clipboard);
  if (isClipboardAvailable) {
    await copyToClipBoard(getWebQuotePageURL({ cartId }));
  }
  setShowToast({
    message: isClipboardAvailable
      ? 'Web Quote Link Copied to ClipBoard'
      : 'Failed to copy to clipboard',
    show: true,
    successToast: isClipboardAvailable
  });
};

export const sectionLabelOperations = ({
  bottomSectionRef,
  cartItemRef,
  newState,
  section,
  setUnSavedChangesErrorMessage,
  setUpdateViewSection,
  updateEditViewSection
}) => {
  const activeSection = Object.keys(updateEditViewSection).find(
    (key) => updateEditViewSection[key] === VIEW_EDIT_MODE.EDIT
  );
  if (newState === VIEW_EDIT_MODE.EDIT && activeSection) {
    setUnSavedChangesErrorMessage((prevState) => ({
      ...prevState,
      [activeSection]: `There are unsaved changes in this block, please
      Save or Discard the changes first to proceed.`
    }));
    if (activeSection === SECTION_LABELS_ENUM.CART_ITEM) {
      cartItemRef?.current.scrollIntoViewIfNeeded();
    } else if (
      activeSection === SECTION_LABELS_ENUM.CART_NOTE ||
      activeSection === SECTION_LABELS_ENUM.PRICE_SUMMARY
    ) {
      bottomSectionRef?.current.scrollIntoView({ behavior: 'smooth' });
    }
    return;
  }
  setUnSavedChangesErrorMessage(defaultErrorMessagesOfSectionLabels);
  setUpdateViewSection((prevState) => ({
    ...prevState,
    [section]: newState
  }));
};

export const copyQuoteLink = ({
  cartId,
  copyToClipBoard,
  event,
  setShowPDFDownloadConfirmationModal,
  setShowToast
}) => {
  event.stopPropagation();
  setShowPDFDownloadConfirmationModal(false);
  copyWebQuoteLink({
    cartId,
    copyToClipBoard,
    setShowToast
  });
};

export const getRackPriceForUnOrderedCartItem = ({
  availableDiscount,
  price: { price = 0, minPrice = 0 } = {},
  type: productType
}) => {
  if (productType === PRODUCT_TYPE.QUOTE) {
    return `Price starting from AED ${parseFormatPriceValueFromAPI(
      minPrice || 0
    )}`;
  }

  const isDiscountAvailable =
    availableDiscount?.isActive && availableDiscount?.value;

  const priceAfterDiscount = isDiscountAvailable
    ? price - (price * (availableDiscount?.value || 0)) / 10000
    : price;

  const prefix = `${isDiscountAvailable ? 'AED ' : ''}`;
  const value = parseFormatPriceValueFromAPI(Number(priceAfterDiscount || 0));

  return `${prefix}${value}`;
};

export const getRackPriceForOrderedCartItem = ({
  rackDiscountPercentage,
  type: productType,
  unitListedPrice
}) => {
  if (productType === PRODUCT_TYPE.QUOTE) {
    return 0;
  }

  const isDiscountAvailable = Boolean(rackDiscountPercentage);

  const priceAfterDiscount = isDiscountAvailable
    ? unitListedPrice -
      (unitListedPrice * (rackDiscountPercentage || 0)) / 10000
    : unitListedPrice;

  const prefix = `${isDiscountAvailable ? 'AED ' : ''}`;
  const value = parseFormatPriceValueFromAPI(Number(priceAfterDiscount || 0));

  return `${prefix}${value}`;
};

const sortEventDetailsForDisplay = (
  { userEventDetailsBody, ...rest },
  isMobile
) => {
  const sortKey = isMobile ? 'sortOrderMobile' : 'sortOrderWeb';
  return {
    ...rest,
    userEventDetailsBody: Object.values(userEventDetailsBody).sort(
      (a, b) => a[sortKey] - b[sortKey]
    )
  };
};

export const getUserEventCartDetails = ({ router, userCartDetails }) => {
  const {
    deliveryDate,
    pickupDate,
    userCart: { hostCreditEntity, mobile: eventContactNumber } = {},
    userCartId: userId,
    userEventSummary
  } = userCartDetails;
  const { id: hostCreditEntityId, name: hostCreditEntityName = null } =
    hostCreditEntity || {};
  const { userEventDetails } = userEventSummary;
  const [
    {
      checkoutEvent: { event: { name: checkoutEventName = 'NA' } = {} } = {},
      eventAddress,
      eventContactName,
      eventDate,
      eventStartTime,
      opsUser,
      trnNumber
    }
  ] = userEventDetails || [{}];

  const trnLabel = trnNumber ? `TRN: ${trnNumber}` : 'NA';

  return sortEventDetailsForDisplay({
    userEventDetailsBanner: {
      eventName: {
        sortOrderMobile: null,
        sortOrderWeb: null,
        value: checkoutEventName
      },
      trnNumber: {
        sortOrderMobile: null,
        sortOrderWeb: null,
        value: trnLabel
      }
    },
    userEventDetailsBody: {
      deliveryDate: {
        alt: 'delivery date',
        className: 'hidden md:flex',
        icon: 'delivery-truck-brand-icon.svg',
        id: 'delivery-date',
        onHoverCopy: true,
        sortOrderMobile: 1,
        sortOrderWeb: 4,
        tooltipText: 'Delivery Date',
        value: deliveryDate
          ? convertToShortMonthDateFormat(deliveryDate)
          : 'NA'
      },
      hostCreditEntityName: {
        additionalLabelStyle: hostCreditEntityId
          ? 'cursor-pointer hover:text-brand'
          : '',
        alt: 'host credit entity name',
        icon: 'host-credit-entity-brand-icon.svg',
        id: 'host-credit-entity-name',
        onClick: () =>
          hostCreditEntityId &&
          router.push(
            getPageURL({
              pageName: PAGE_NAME.HOST_CREDIT_ENTITY_HOST_LIST.label,
              pathParams: {
                hostCreditEntityId
              }
            })
          ),
        onHoverCopy: true,
        sortOrderMobile: 1,
        sortOrderWeb: 1,
        tooltipText: 'Host Credit Entity Name',
        value: hostCreditEntityName || 'NA'
      },
      trnNumber: {
        alt: 'trn number',
        icon: 'trn-brand-icon.svg',
        id: 'trn-number',
        onHoverCopy: true,
        sortOrderMobile: 2,
        sortOrderWeb: 5,
        tooltipText: 'TRN Number',
        value: trnLabel
      },
      eventAddress: {
        alt: 'event address icon',
        icon: 'location-brand-icon.svg',
        id: 'event-location',
        onHoverCopy: true,
        sortOrderMobile: 7,
        sortOrderWeb: 7,
        tooltipText: 'Event Location',
        value: eventAddress
      },
      eventContactName: {
        additionalLabelStyle: 'cursor-pointer hover:text-brand',
        alt: 'host name icon',
        icon: 'host-brand-v1-icon.svg',
        id: 'host-name',
        onHoverCopy: true,
        onClick: () =>
          router.push(
            getPageURL({
              pageName: PAGE_NAME.HOST_EVENT_LIST.label,
              pathParams: {
                userId
              }
            })
          ),
        sortOrderMobile: 3,
        sortOrderWeb: 2,
        tooltipText: 'Host Name',
        value: eventContactName
      },
      eventContactNumber: {
        alt: 'host phone number icon',
        icon: 'phone-brand-icon.svg',
        id: 'host-phone-number',
        onHoverCopy: true,
        sortOrderMobile: 4,
        sortOrderWeb: 6,
        tooltipText: 'Host Phone Number',
        value: eventContactNumber
      },
      eventDate: {
        alt: 'event date',
        icon: 'date-brand-icon.svg',
        id: 'event-date-time',
        onHoverCopy: true,
        sortOrderMobile: 5,
        sortOrderWeb: 3,
        tooltipText: 'Event Date & Time',
        value: convertToDateTimeString({
          date: eventDate,
          time: eventStartTime
        })
      },
      pickupDate: {
        alt: 'pickup date',
        className: 'hidden md:flex',
        icon: 'pickup-truck-brand-icon.svg',
        id: 'pickup-date',
        onHoverCopy: true,
        sortOrderMobile: 6,
        sortOrderWeb: 9,
        tooltipText: 'Pickup Date',
        value: pickupDate ? convertToShortMonthDateFormat(pickupDate) : 'NA'
      },
      opsUser: {
        alt: 'event planner name',
        className: 'md:hidden',
        icon: 'planner-brand-icon-v1.svg',
        id: 'event-planner-name',
        onHoverCopy: true,
        sortOrderMobile: 8,
        sortOrderWeb: 7,
        tooltipText: 'Event Planner Name',
        value: opsUser?.name || ''
      }
    }
  });
};

export const otherFields = [
  {
    dbName: 'paymentProviderId',
    infoMessage:
      'Cheque#, Cash Receipt Voucher#, Bank Transaction Reference#, or Other Reference# as applicable.',
    inputType: 'text',
    label: 'Reference #',
    placeholder: 'Reference'
  },
  {
    dbName: 'paymentRemarks',
    infoMessage:
      'Capture additional details regarding the payment here, especially when the entire order amount is not received.',
    inputClassName: 'w-full',
    inputType: 'text',
    label: 'Payment Remarks',
    placeholder: 'Payment Remarks'
  }
];

export const modeOfConfirmations = {
  EMAIL: { label: 'Email', value: 'EMAIL' },
  PREPAID: { label: 'Prepaid', value: 'PREPAID' },
  PURCHASE_ORDER: { label: 'Purchase Order', value: 'PURCHASE_ORDER' },
  WHATSAPP: { label: 'WhatsApp', value: 'WHATSAPP' }
};

export const paymentModes = {
  BANK: {
    alt: 'bank icon',
    icon: 'banking.svg',
    label: 'Bank Transfer',
    value: 'BANK'
  },
  CASH: { alt: 'cash icon', icon: 'cash.svg', label: 'Cash', value: 'CASH' },
  CHEQUE: {
    alt: 'cheque icon',
    icon: 'cheque.svg',
    label: 'Cheque',
    value: 'CHEQUE'
  },
  PG_STRIPE: {
    alt: 'strip icon',
    icon: 'stripe.svg',
    label: 'Stripe ',
    value: 'PG_STRIPE'
  },
  PG_TAP: { alt: 'tap icon', icon: 'tap.svg', label: 'Tap', value: 'PG_TAP' }
};

export const amountFields = [
  {
    dbName: 'amountReceived',
    inputType: 'number',
    label: 'Amount received',
    placeholder: 'Enter a positive real number without AED'
  },
  {
    dbName: 'orderTotal',
    disable: true,
    inputType: 'number',
    label: 'Grand Total #'
  },
  {
    dbName: 'amountDue',
    disable: true,
    inputType: 'number',
    label: 'Amount Due'
  }
];

export const getCartItemFields = ({
  haflaMarginPercent,
  partnerLegalName,
  quantity,
  sellingPrice,
  unitSellingPrice
}) => [
  { content: quantity, className: 'w-20 self-center' },
  {
    className: 'w-24 self-center',
    content: parseFormatPriceValueFromAPI(unitSellingPrice)
  },
  {
    className: 'w-30 self-center',
    content: parseFormatPriceValueFromAPI(sellingPrice)
  },

  {
    className: 'w-40 self-center',
    content: partnerLegalName
  },
  {
    className: `w-20 self-center ${
      Number(parseFormatPriceValueFromAPI(haflaMarginPercent)) >= 0
        ? 'text-green'
        : 'text-red-500'
    }`,
    content: `${parseFormatPriceValueFromAPI(haflaMarginPercent)} %`
  }
];

export const getCartItemMediaDirectoryPath = ({ cartId, cartItemId }) =>
  `API/${environmentName}/db/carts/${cartId}/${cartItemId}/`;

export const disableConfirmOrderCTA = ({ watch }) => {
  const amountDue = Number(watch('amountDue'));
  const payWithCreditBalance = watch('payWithCreditBalance');
  const modeOfConfirmation = watch('modeOfConfirmation');

  if (!modeOfConfirmation) {
    return true;
  }
  if (modeOfConfirmation === modeOfConfirmations.PREPAID.value) {
    return !(amountDue <= 0 || payWithCreditBalance);
  }

  return !payWithCreditBalance;
};

export const shouldDisableOrderBooking = ({
  cartDetails,
  hasUnavailableCartItems
}) => {
  const { isDefault, status: cartStatus, userEventSummary } = cartDetails;
  const orderBookingDisabledCartStates = [
    CART_STATUS.ABANDON.value,
    CART_STATUS.DRAFT.value,
    CART_STATUS.ORDER.value
  ];
  const isCartInInvalidStatusForOrderBooking =
    orderBookingDisabledCartStates.includes(cartStatus);

  return (
    hasUnavailableCartItems ||
    isCartInInvalidStatusForOrderBooking ||
    isDefault ||
    !userEventSummary?.userEventDetails
  );
};

const isCartEditable = ({ status }) => {
  const editableCartStatuses = [
    CART_STATUS.COLLABORATE.value,
    CART_STATUS.DRAFT.value
  ];
  return editableCartStatuses.includes(status);
};

export const fetchReadOnlyCartDetails = async ({
  cartId,
  setCartDetails,
  setLoading,
  setShowToast
}) => {
  setLoading(true);
  try {
    const {
      entity: cartDetailResponse,
      message,
      status
    } = await getReadOnlyCartDetailsAPI({
      cartId
    });

    if (status && !_.isEmpty(cartDetailResponse)) {
      const isLegacyCartWithoutDerivedValues = isEmptyOrNull(
        cartDetailResponse.cartDerivedValue
      );
      if (isLegacyCartWithoutDerivedValues) {
        setShowToast({
          message: `cartDerivedValue is ${cartDetailResponse.cartDerivedValue}`,
          show: true,
          successToast: status
        });
      } else {
        const updatedUserEventDetailWithAddress = {
          ...cartDetailResponse.cartUserEvent.userEventDetail,
          eventAddress:
            cartDetailResponse.cartUserEvent.userEventDetail.eventAddress
              .formattedAddress
        };

        const cartDetail = {
          ...cartDetailResponse,
          userEventSummary: {
            id: cartDetailResponse.cartUserEvent.userEventDetail.id,

            zendeskTicketId:
              cartDetailResponse.cartUserEvent.userEvent.zendeskTicketId,

            userEventDetails: [updatedUserEventDetailWithAddress]
          },
          isReadOnly: true,
          isCartEditable: false
        };
        setCartDetails(cartDetail);
      }
    }

    !status &&
      setShowToast({
        message,
        show: true,
        successToast: status
      });
  } finally {
    setLoading(false);
  }
};

export const fetchCartDetails = async ({
  cartId,
  setCartDetails,
  setLoading = () => {},
  setShowToast = () => {}
}) => {
  setLoading(true);
  try {
    const {
      entity: cartDetailResponse,
      message,
      status
    } = await getCartDetailsByIdAPI({
      cartId
    });

    if (status && !_.isEmpty(cartDetailResponse)) {
      if (
        // TODO: Faizan - once the bug related to null cartDerivedValue is fixed we can remove this if block
        // TODO: Sidd+TJ+RJ - to refactor such that we use 1 convetion cartDerivedValue v/s derivedValues - preferred is cartDerivedValue
        cartDetailResponse.cartDerivedValue ||
        cartDetailResponse.derivedValues
      ) {
        const cartDetail = {
          ...cartDetailResponse,
          isCartEditable: isCartEditable(cartDetailResponse)
        };
        setCartDetails(cartDetail);
      } else {
        // TODO: Sidd+TJ+RJ please correct the message handle in null case
        setShowToast({
          message: `cartDerivedValue is ${cartDetailResponse.cartDerivedValue}; derivedValues is ${cartDetailResponse.derivedValues}`,
          show: true,
          successToast: status
        });
      }
      !status &&
        setShowToast({
          message,
          show: true,
          successToast: status
        });
    }
  } finally {
    setLoading(false);
  }
};

export const updateCartDetailAPI = async ({
  cartId,
  setCartDetails,
  setShowToast,
  updatedCartDetail,
  userId
}) => {
  const { message, status } = await updateCartInfoAPI({
    cartId,
    data: updatedCartDetail,
    userCartId: userId
  });
  if (status) {
    await fetchCartDetails({ cartId, setCartDetails });
  }
  !status && setShowToast({ message, show: true, successToast: status });
};

export const invokeTenderAndPublishBids = async ({
  cartId,
  firstTender,
  setCartDetails,
  setLoading,
  setShowToast
}) => {
  setLoading(true);

  const { message, status } = await createTenderAndPublishBids({
    firstTender,
    data: { cartId }
  });
  if (status) {
    await fetchCartDetails({ cartId, setCartDetails });
  }
  setShowToast({
    message: message ?? message[0],
    show: true,
    successToast: status
  });

  setLoading(false);
};

export const deleteCartItem = async ({
  cartDetails,
  setCartDetails,
  setLoading,
  setShowToast,
  userId
}) => {
  setLoading(true);
  const { cartId, cartItemId } = cartDetails;

  const { message, status } = await deleteItemFromCartAPI({
    cartId,
    cartItemId,
    userCartId: userId
  });

  if (status) {
    await fetchCartDetails({ cartId, setCartDetails });

    const deletedItem = cartDetails.cartItems.find(
      ({ id }) => id === cartItemId
    );
    const {
      id,
      product: {
        name,
        availableDiscount,
        price: { price },
        type
      },
      quantity
    } = deletedItem;
    captureGTMEventRemoveFromCart({
      availableDiscount,
      id,
      name,
      pageName: PAGE_NAME.PLANNER_CART.label,
      price,
      quantity,
      type
    });
  }

  setShowToast({ message, show: true, successToast: status });
  setLoading(false);
};

export const cloneCartItem = async ({
  cartId,
  cartItemId,
  setCartDetails,
  setLoading,
  setShowToast,
  userId
}) => {
  setLoading(true);
  const { message, status } = await cloneCartItemAPI({
    cartItemId,
    cartId,
    userCartId: userId
  });
  if (status) {
    await fetchCartDetails({ cartId, setCartDetails });
  }
  setShowToast({ message, show: true, successToast: status });
  setLoading(false);
};

export const updateCartItemDetailAPI = async ({
  cartDetails,
  cartId,
  itemDetail,
  setCartDetails,
  setCartItemViewEditMode,
  setLoading,
  setShowToast,
  userId
}) => {
  setLoading(true);
  const currentItem = cartDetails.cartItems.find(
    ({ id }) => itemDetail.id === id
  );
  const {
    id,
    product: {
      name,
      price: { price, minPrice },
      type
    }
  } = currentItem;

  updateGTMEventCartQuantity({
    currentQuantity: itemDetail.quantity,
    id,
    minPrice,
    name,
    oldQuantity: currentItem.quantity,
    pageName: PAGE_NAME.PLANNER_CART.label,
    price,
    type
  });

  const { message, status } = await updateCartItemAPI({
    cartId,
    cartItemId: itemDetail.id,
    data: convertValueFieldsToLowerDenomination(itemDetail),
    userCartId: userId
  });
  if (status) {
    setCartItemViewEditMode(VIEW_EDIT_MODE.VIEW);
    await fetchCartDetails({ cartId, setCartDetails });
  }
  setShowToast({ message, show: true, successToast: status });
  setLoading(false);
};

export const deleteCartItemMedia = async ({
  cartId,
  cartItemId,
  cartItemMediaId,
  setCartDetails,
  setLoading,
  setShowToast
}) => {
  setLoading(true);
  const { message, status } = await deleteCartItemMediaAPI({
    cartId,
    cartItemId,
    cartItemMediaId
  });
  if (status) {
    await fetchCartDetails({ cartId, setCartDetails });
  }
  setShowToast({ message, show: true, successToast: status });
  setLoading(false);
};

export const invokeCartItemSortOrder = async ({
  cartId,
  cartItemListWithRevisedOrder,
  setCartDetails,
  setLoading,
  setShowToast
}) => {
  setLoading(true);
  const { message, status } = await updateCartItemSortOrderAPI({
    cartId,
    data: { cartItemList: cartItemListWithRevisedOrder }
  });
  if (status) {
    await fetchCartDetails({ cartId, setCartDetails });
  }
  setShowToast({ message, show: true, successToast: status });
  setLoading(false);
};

export const invokeCartItemMediaInSortOrder = async ({
  cartId,
  cartItemId,
  changedCartItemMediaSortOrder,
  setCartDetails,
  setLoading,
  setShowToast
}) => {
  setLoading(true);
  const { message, status } = await updateCartItemMediaSortOrderAPI({
    cartItemMediaToSort: changedCartItemMediaSortOrder,
    cartItemId,
    cartId
  });
  setShowToast({ message, show: true, successToast: status });
  if (status) {
    await fetchCartDetails({ cartId, setCartDetails });
  }
  setLoading(false);
};

export const onClickGeneratePaymentLink = async ({
  cartId,
  copyToClipBoard,
  setIsPaymentLinkGenerated,
  setShowToast,
  userId
}) => {
  const isClipboardAvailable = Boolean(navigator.clipboard);
  if (isClipboardAvailable) {
    await copyToClipBoard(
      generatePaymentLink({
        paymentSourceType: PAYMENT_SOURCE_TYPE.CART,
        referenceId: cartId,
        userId
      })
    );
  }
  setIsPaymentLinkGenerated(true);
  setShowToast({
    message: isClipboardAvailable
      ? 'Successfully generated and copied payment link'
      : 'Failed to copy to clipboard',
    show: true,
    successToast: isClipboardAvailable
  });
};

const sendDataToGAForPurchaseEvent = async (orderId) => {
  const { entity, status } = await getOrderDetailsAPI({
    orderId
  });
  status &&
    captureGTMEventPurchaseCompletion({
      orderItems: entity.orderItems,
      orderId,
      orderTotal: entity.orderTotal,
      paymentFlowSource: PAYMENT_FLOW_SOURCE.PLANNER_CART
    });
  return status;
};

export const submitBookOrder = async ({
  bookOrderPaymentDetails,
  cartId,
  router,
  setLoading,
  setShowToast,
  toggleBookOrderModalView,
  userId
}) => {
  setLoading(true);
  const {
    entity: { orderId } = {},
    message,
    status
  } = await cartCheckoutAndBookOrderAPI({
    body: {
      bookOrderPaymentDetails
    },
    params: {
      cartId,
      userCartId: userId
    }
  });
  if (!status) {
    setShowToast({ message, show: true, successToast: status });
    setLoading(false);
    await toggleBookOrderModalView();
    return;
  }

  // TODO: when should be event sent to GA as soon as checkout api returns result or after page redirection to payment response --> as soon as checkout api returns "success" in case of failure should not redirect to paymentSuccessUrl and should not trigger sendDataToGAForPurchaseEvent. FAIZAN fix this !

  // TODO: PR discussion: also current the code is diffrent for sendDataToGAForPurchaseEvent in cart plan & payment response page --> yeh entity ko check karo 4 keys send karni hai { orderItems, orderId, orderTotal, paymentFlowSource} --- FAIZAN fix this !!

  await sendDataToGAForPurchaseEvent(orderId);
  const orderDetailsPageURL = getPageURL({
    pageName: PAGE_NAME.ORDER_VIEW.label,
    pathParams: { orderId },
    queryParams: {
      showBookOrderSuccessBanner: true
    }
  });
  router.push(orderDetailsPageURL);
  setLoading(false);
};

export const updateShowProductMediaToggle = async ({
  cartId,
  cartItemId,
  setIsShowProductMedia,
  setShowToast,
  showProductMediaFlag,
  userId
}) => {
  const { message, status } = await updateCartItemShowProductMediaToggleAPI({
    cartId,
    cartItemId,
    data: {
      showProductMedia: showProductMediaFlag
    },
    userCartId: userId
  });
  status && setIsShowProductMedia(showProductMediaFlag);
  !status &&
    setShowToast({
      message: message || 'Failed to update show product media flag',
      show: true,
      successToast: false
    });
};
