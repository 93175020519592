import Image from 'next/image';

import { Text, TimeDropDown } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import { checkIsEmptyOrDummyEventDetail } from '@/services/userEventCartList.service';

const TimePickerWithLabel = ({
  errors,
  eventFormDetails,
  isUserEventDetailInEditMode,
  selectedEventStartTime,
  setEventFormDetails
}) => {
  const dummyValueErrorStyleForEventTime = checkIsEmptyOrDummyEventDetail({
    key: 'eventStartTime',
    value: selectedEventStartTime
  })
    ? '!text-error-base'
    : '';

  const handleEventTime = (e) =>
    setEventFormDetails(() => ({
      ...eventFormDetails,
      selectedEventStartTime: e.target.value
    }));

  return (
    <div className='w-full flex flex-col gap-2'>
      <Text
        {...{
          className: 'font-medium text-nero',
          content: 'Event Start Time *',
          HtmlTag: 'label'
        }}
      />
      <div
        className={`flex flex-row items-center border border-platinum rounded-lg px-4 ${errors?.eventTime ? 'border-brand' : ''} ${isUserEventDetailInEditMode ? 'hover:border-brand' : ''}`}
      >
        <Image
          alt='time'
          className='cursor-pointer'
          height={0}
          src={`${staticMediaStoreBaseURL}/icons/${
            isUserEventDetailInEditMode
              ? 'time-icon-red.svg'
              : 'time-gray-icon.svg'
          }`}
          style={{ height: 24, width: 24 }}
          width={0}
        />
        <TimeDropDown
          {...{
            className: `font-medium placeholder-silver focus:outline-none h-12 ${dummyValueErrorStyleForEventTime}`,
            disabled: !isUserEventDetailInEditMode,
            eventTime: selectedEventStartTime,
            handleEventTime
          }}
        />
      </div>
      {errors.eventTime && (
        <Text
          {...{
            className:
              'mt-1 w-full text-red-500 text-xs font-medium capitalize invalid-feedback',
            content: errors.eventTime.message,
            HtmlTag: 'span'
          }}
        />
      )}
    </div>
  );
};

export default TimePickerWithLabel;
