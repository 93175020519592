import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import { useInfiniteHits, useStats } from 'react-instantsearch';

import { staticMediaStoreBaseURL } from '@/config/common';
import { DELAY_SEARCHKIT_RENDER_TIME } from '@/helpers/searchkit';

const InstantSearchInfiniteScroll = ({
  hitComponent: HitComponent,
  ...props
}) => {
  const { items, isLastPage, showMore } = useInfiniteHits(props);
  const sentinelRef = useRef(null);

  useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLastPage) {
            showMore();
          }
        });
      });

      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }
    return () => {};
  }, [isLastPage, showMore]);

  return (
    <div className='ais-InfiniteHits flex flex-1 mt-[70px] md:mt-0'>
      <ul className='ais-InfiniteHits-list flex flex-col gap-4 w-full'>
        {items.map((hit) => (
          <li
            className='ais-InfiniteHits-item hover:text-nero !border-none !p-0 !rounded-none shadow-none'
            key={hit.objectID}
          >
            <HitComponent hit={hit} />
          </li>
        ))}
        <li
          aria-hidden='true'
          className='ais-InfiniteHits-sentinel'
          ref={sentinelRef}
        />
      </ul>
    </div>
  );
};

const InstantSearchInfiniteScrollBody = ({
  instantSearchInfiniteScrollProps
}) => {
  const { nbHits: resultsCount } = useStats();

  const hasResults = resultsCount > 0;

  const [displayResults, setDisplayResults] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplayResults(true);
    }, DELAY_SEARCHKIT_RENDER_TIME);
    return () => clearTimeout(timer);
  }, []);

  return displayResults ? (
    <>
      {hasResults ? (
        <InstantSearchInfiniteScroll {...instantSearchInfiniteScrollProps} />
      ) : (
        <div className={`flex justify-center gap-3 py-20 text-center mx-auto`}>
          <div className='self-center'>
            <Image
              alt='404 image'
              className='mx-auto'
              height={0}
              priority={true}
              src={`${staticMediaStoreBaseURL}/icons/search-result-not-found.svg`}
              style={{ height: 314, width: 805 }}
              width={0}
            />
            <div className='text-2xl font-medium mt-5 text-chinese-black'>
              The server is currently unavailable. Please try again later.
            </div>
          </div>
        </div>
      )}
    </>
  ) : null;
};

export default InstantSearchInfiniteScrollBody;
