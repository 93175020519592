import { useRouter } from 'next/router';

import { CloseIcon, Text, TextKeyValuePair } from '@/components/atomic/nuclei';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';

const ViewAllUserEventsPageHeader = ({
  count,
  headerTitleStyle = '',
  showCount = true,
  title
}) => {
  const router = useRouter();

  return (
    <div
      className={`flex flex-row sticky bg-white top-0 h-12 md:h-16 pt-2 z-50 px-3 md:px-8 md:mt-2 md:shadow-none ${headerTitleStyle}`}
    >
      {showCount ? (
        <TextKeyValuePair
          {...{
            className: 'flex-1 flex-row items-center gap-2',
            label: title,
            labelClass:
              'text-base md:text-3xl font-semibold text-dim-gray md:leading-6 leading-[19.5px]',
            value: count,
            valueClassName:
              'bg-brand-gradient text-white rounded-full min-w-6 px-1 h-6 text-sm font-medium items-center justify-center flex'
          }}
        />
      ) : (
        <Text
          {...{
            className:
              'flex-1 text-base md:text-3xl font-semibold text-dim-gray flex gap-2 flex-row items-center gap-2',
            content: title
          }}
        />
      )}
      <CloseIcon
        {...{
          className: 'right-4 top-3 md:top-4 bg-white flex-2',
          height: 32,
          icon: 'close-brand-icon.svg',
          onClick: () =>
            router.push(
              getPageURL({
                pageName: PAGE_NAME.ACTION_CENTER.label
              })
            ),
          width: 32
        }}
      />
    </div>
  );
};

export default ViewAllUserEventsPageHeader;
