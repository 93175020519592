import Image from 'next/image';
import { useEffect, useState } from 'react';
import { InfiniteHits, useStats } from 'react-instantsearch';

import { Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import { DELAY_SEARCHKIT_RENDER_TIME } from '@/helpers/searchkit';

const CustomInfiniteHits = ({ infiniteHitsProps }) => {
  const { nbHits: resultsCount } = useStats();

  const hasResults = resultsCount > 0;

  const [displayResults, setDisplayResults] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplayResults(true);
    }, DELAY_SEARCHKIT_RENDER_TIME);
    return () => clearTimeout(timer);
  }, []);

  return displayResults ? (
    <>
      {hasResults ? (
        <InfiniteHits {...infiniteHitsProps} />
      ) : (
        <div className='flex justify-center gap-3 py-20 text-center'>
          <div className='self-center'>
            <Image
              alt='404 image'
              className='mx-auto'
              height={0}
              priority={true}
              src={`${staticMediaStoreBaseURL}/icons/search-result-not-found.svg`}
              style={{ height: 314, width: 805 }}
              width={0}
            />
            <Text
              className='text-2xl font-medium mt-5 text-chinese-black'
              content='The server is currently unavailable. Please try again later.'
            />
          </div>
        </div>
      )}
    </>
  ) : null;
};

export default CustomInfiniteHits;
