import Image from 'next/image';
import { ClearRefinements } from 'react-instantsearch';

import { Text, TextWithIcon } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';

const SearchkitFilterHeader = ({
  excludedAttributes = [],
  onClickClearAll = () => {},
  resultCountLabel,
  setShowFilters,
  showFilters
}) => {
  const showCountLabel = !showFilters && resultCountLabel;
  return (
    <div
      className={`self-top sticky top-0 bg-white z-101 ${showCountLabel ? 'w-[28rem]' : 'w-[18.5rem]'}`}
    >
      <div
        className={`flex items-center ${showFilters ? 'justify-between mt-1' : ''}`}
      >
        <div
          className='flex gap-2 cursor-pointer px-3 py-1 bg-brand-gradient rounded-r-lg'
          onClick={() => setShowFilters(!showFilters)}
        >
          <TextWithIcon
            {...{
              alt: 'order list filter icon',
              className: 'self-center',
              icon: 'filter-white-icon.svg',
              iconHeight: 20,
              iconWidth: 20,
              label: 'Filters',
              labelStyle: 'text-base text-white font-light'
            }}
          />
          <div
            className='self-center'
            onClick={() => setShowFilters(!showFilters)}
          >
            <Image
              alt='view-icon'
              className='cursor-pointer'
              height={0}
              src={`${staticMediaStoreBaseURL}/icons/${
                showFilters ? 'view-white-icon.svg' : 'hide-icon.svg'
              }`}
              style={{ height: 20, width: 20 }}
              width={0}
            />
          </div>
        </div>
        {showFilters && (
          <div onClick={onClickClearAll}>
            <ClearRefinements
              {...{
                classNames: {
                  button: 'searchkit-clear-all-button',
                  root: 'searchkit-clear-all-button-container items-center'
                },
                excludedAttributes,
                translations: {
                  resetButtonText: 'Clear All'
                }
              }}
            />
          </div>
        )}
        {showCountLabel && (
          <Text
            {...{
              className: `text-xl font-medium text-brand ml-5`,
              content: resultCountLabel
            }}
          />
        )}
      </div>
      {showFilters && (
        <div className='border-t border-platinum mt-4 mb-4'></div>
      )}
    </div>
  );
};

export default SearchkitFilterHeader;
