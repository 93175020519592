import Image from 'next/image';

import {
  Text,
  TextKeyValuePair,
  TextWithIcon
} from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';

const CreateEventIllustration = () => (
  <div className='w-1/2 hidden md:block create-event-bg p-8 relative z-50'>
    <Image
      alt='hafla white logo'
      height={0}
      src={`${staticMediaStoreBaseURL}/icons/hafla-white-logo.svg`}
      style={{ height: 40, width: 125 }}
      width={0}
    />
    <TextWithIcon
      {...{
        className: 'flex flex-col',
        iconSuffix: {
          additionalProps: {
            priority: true
          },
          alt: 'start event planning journey icon',
          icon: 'start-event-planning-journey.svg',
          iconHeight: 440,
          iconStyle: 'mx-auto mt-6',
          iconWidth: 516
        },
        label: 'Start Your Event Planning Journey',
        labelStyle: 'mt-7 text-center text-white font-semibold text-2xl'
      }}
    />
    <div className='mt-8 p-4 shadow-xl bg-white/10 backdrop-blur-md rounded-lg'>
      <Text
        {...{
          className: 'text-white text-base font-medium',
          content: 'Key Points To Remember:'
        }}
      />
      <TextKeyValuePair
        {...{
          className: 'flex flex-col',
          label:
            '• Make sure to fill in all fields marked with an asterisk (*) to enable event creation',
          labelClass: 'text-white text-sm font-medium mt-3 pl-3',
          spaceTop: '',
          value:
            '• The event will be assigned to the planner creating it. To assign it to another planner, select their name from the dropdown.',
          valueClassName: 'text-white text-sm font-medium pl-3'
        }}
      />
    </div>
  </div>
);

export default CreateEventIllustration;
