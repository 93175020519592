import Image from 'next/image';
import { PropTypes } from 'prop-types';
import { useState } from 'react';

import { staticMediaStoreBaseURL } from '@/config/common';
import copyToClipBoard from '@/lib/copyToClipBoard';

const TooltipText = ({
  additionalTooltipClass,
  tooltipPositionClass,
  tooltipText
}) => (
  <span
    className={`bg-black absolute opacity-0 invisible transition w-max rounded bg-gray-800 p-1.5 px-2 rounded text-xs duration-[150ms] delay-[150ms] text-white group-hover:opacity-100 group-hover:visible z-max ${tooltipPositionClass}-8 ${additionalTooltipClass}`}
  >
    {tooltipText}
  </span>
);

const TextWithTooltip = ({
  additionalTooltipClass,
  className = '',
  content,
  HtmlTag = 'div',
  onHoverCopy = false,
  tooltipPositionClass = 'top',
  tooltipText,
  ...rest
}) => {
  const [showCopied, setShowCopied] = useState(false);

  const handleCopy = async (e) => {
    if (!onHoverCopy) return;
    e.stopPropagation();
    e.preventDefault();
    await copyToClipBoard(content);
    setShowCopied(true);
    setTimeout(() => setShowCopied(false), 2000);
  };

  return (
    <div
      className='group relative inline-block'
      onClick={handleCopy}
    >
      <HtmlTag {...{ className, ...rest }}>
        {showCopied ? (
          <div className='flex items-center gap-1'>
            <Image
              alt='copy'
              height={0}
              src={`${staticMediaStoreBaseURL}/icons/check-completed-green-icon.svg`}
              style={{
                height: 16,
                width: 16
              }}
              width={0}
            />
            <div>Copied to clipboard</div>
          </div>
        ) : (
          content
        )}
      </HtmlTag>
      {!showCopied && (
        <TooltipText
          {...{
            additionalTooltipClass,
            tooltipPositionClass,
            tooltipText
          }}
        />
      )}
    </div>
  );
};

const Text = ({
  additionalTooltipClass,
  className = '',
  content,
  HtmlTag = 'div',
  onHoverCopy = false,
  tooltipPositionClass = 'top',
  tooltipText,
  ...rest
}) => {
  return tooltipText ? (
    <TextWithTooltip
      {...{
        additionalTooltipClass,
        className,
        content,
        HtmlTag,
        onHoverCopy,
        tooltipPositionClass,
        tooltipText,
        ...rest
      }}
    />
  ) : (
    <HtmlTag {...{ className, ...rest }}>{content}</HtmlTag>
  );
};

export default Text;

Text.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
    PropTypes.string
  ]).isRequired,
  HtmlTag: PropTypes.string,
  className: PropTypes.string
};
