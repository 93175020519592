import { useInstantSearch, useStats } from 'react-instantsearch';

const NoResultsBoundary = ({
  children,
  fallback,
  isMultiInstanceSearch = false
}) => {
  const { nbHits: resultCount } = useStats();
  const {
    results: { __isArtificial: isArtificial, nbHits }
  } = useInstantSearch();
  const noResults = isMultiInstanceSearch
    ? resultCount === 0
    : !isArtificial && nbHits === 0;

  return noResults ? fallback : children;
};

export default NoResultsBoundary;
